import { faExternalLink, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Input, useBreakpoint } from "@intility/bifrost-react";
import type { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { DataTable } from "~/components/table/DataTable";
import type { Cluster } from "~/types";
import styles from "./clusterTable.module.css";
import CallToAction from "./CallToAction";
import { ClusterStatusCounts } from "./ClusterStatusCounts";
import { useClusters } from "~/api/getClusters";
import { clusterColumns } from "./ClusterTableColumns";
import { useNavigate } from "react-router";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";

export const ClusterTable = () => {
  const clusters = useClusters({ refetchInterval: 10000 });
  const toMedium = useBreakpoint(null, "medium");
  const navigate = useNavigate();

  const [clusterFilter, setClusterFilter] = useState("");

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  });

  if (clusters.isError) {
    return <UnavailableInfoError />;
  }

  if (clusters.isSuccess && clusters.data.length === 0) {
    return <CallToAction />;
  }

  const handleRowClick = (rowData: Cluster) => {
    const clusterId = rowData.id;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(`/clusters/${clusterId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.headerTitle}>All clusters</span>
        <ClusterStatusCounts clusters={clusters.data ?? []} />
      </div>

      <div className={styles.searchInputContainer}>
        <Input
          className={styles.searchInput}
          placeholder="Look up cluster"
          label={undefined}
          icon={faSearch}
          clearable
          rightIcon
          value={clusterFilter}
          onChange={(e) => setClusterFilter(e.target.value)}
        />
      </div>

      <DataTable
        className={styles.table}
        columns={clusterColumns}
        data={clusters.data}
        onPaginationChange={setPagination}
        state={{
          globalFilter: clusterFilter,
          pagination,
        }}
        initialState={{
          sorting: [{ id: "name", desc: false }],
        }}
        isLoading={clusters.isPending}
        noBorder
        limitExpandClick
        onRowClick={handleRowClick}
        ExpandedRowComponent={toMedium ? ExpandedClusterRow : undefined}
      />
    </div>
  );
};

const ExpandedClusterRow = ({ rowData }: { rowData: Cluster }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "var(--bfs8)",
      }}
    >
      <span>Console URL</span>

      <a href={rowData.consoleUrl} target="_blank" rel="noreferrer">
        <Button small state="neutral">
          Connect to cluster
          <Icon icon={faExternalLink} marginLeft />
        </Button>
      </a>
    </div>
  );
};
