import { Badge } from "@intility/bifrost-react";
import { ClusterRole } from "~/constants";

export const ClusterRoleBadge = ({ roles }: { roles: ClusterRole[] }) => {
  const set = new Set<ClusterRole>(roles);

  const isAdmin = set.has("admin");
  const isReader = set.has("reader");

  switch (true) {
    case isAdmin:
      return (
        <Badge state="neutral" inverted>
          Admin
        </Badge>
      );

    case isReader:
      return <Badge state="neutral">Reader</Badge>;

    default:
      return null;
  }
};
