import { ColorSchemedImage } from "~/components/ColorSchemedImage";
import bananaSlipDark from "~/assets/banana-slip-dark.svg";
import bananaSlipLight from "~/assets/banana-slip-light.svg";
import styles from "./UnavailableInfoError.module.css";
import { cn } from "~/utils/clsx";
import type { ReactElement } from "react";

export const UnavailableInfoError = ({
  className,
  errorMessage = "Ooops, this information is not available right now",
  additionalInfo,
}: {
  className?: string;
  errorMessage?: ReactElement | string;
  additionalInfo?: ReactElement;
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <ColorSchemedImage
        srcLight={bananaSlipLight}
        srcDark={bananaSlipDark}
        alt=""
      />

      <p className={styles.errorMessage}>{errorMessage}</p>

      {additionalInfo && <>{additionalInfo}</>}
    </div>
  );
};
