import { cn } from "~/utils/clsx";
import indev from "~/assets/images/landing/indev.gif";
import styles from "./DeveloperProductivity.module.css";

export const DeveloperProductivity = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img
          src={indev}
          alt="Demonstration of indev commands such as 'indev -h' and 'indev cluster create'"
        />

        <div className={styles.textContent}>
          <h3 className="bf-h3">
            Increase <span className={cn(styles.emphasis)}>developer</span>{" "}
            productivity
          </h3>

          <p>
            Manage your developer environment with portal, CLI, or
            infrastructure as code.
          </p>
        </div>
      </div>
    </section>
  );
};
