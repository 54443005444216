import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Grid,
  Inline,
  Input,
  useFloatingMessage,
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Role } from "~/constants";
import { ModalContext } from "~/context/ModalContext";
import { useAddTeamMembers } from "../../api/addTeamMembers";
import type { UserDTO } from "../../api/getUsers";
import { useUserTeams } from "../../api/getUserTeams";
import { TeamRoleDescriptionTable } from "../TeamRoleDescriptionTable";
import styles from "./AddUserToTeamsModal.module.css";
import { useTeams } from "../../api/getTeams";
import { useRole } from "~/hooks/useRole";

const roleOptions = [
  { label: "Member", value: Role.Member },
  { label: "Owner", value: Role.Owner },
];

const addTeamMemberSchema = z.object({
  role: z
    .object(
      {
        label: z.string(),
        value: z.nativeEnum(Role),
      },
      { required_error: "Required" },
    )
    .required(),
  userId: z.string(),
  team: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "Required" },
    )
    .required(),
});

type AddTeamMemberSchema = z.infer<typeof addTeamMemberSchema>;
type TeamOption = { label: string; value: string; isDisabled: boolean };

interface AddUserToTeamsModalProps {
  user: UserDTO;
}

export const AddUserToTeamsModal = ({ user }: AddUserToTeamsModalProps) => {
  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const { control, formState, handleSubmit } = useForm<AddTeamMemberSchema>({
    resolver: zodResolver(addTeamMemberSchema),
    defaultValues: { userId: user.id },
  });

  const allTeams = useTeams();
  const userTeams = useUserTeams(user.id);
  const addTeamMember = useAddTeamMembers();
  const role = useRole();

  const onSubmit = handleSubmit((data) => {
    addTeamMember.mutate(
      {
        teamId: data.team.value,
        dto: [
          {
            roles: [data.role.value],
            subject: { id: data.userId, type: "user" },
          },
        ],
      },
      {
        onError: () => {
          showFloatingMessage(
            <>
              An error occurred while attempting to add the user{" "}
              <strong>{user.name}</strong> to the team{" "}
              <strong>{data.team.label}</strong>. Please try again.
            </>,
            {
              state: "alert",
              noIcon: true,
            },
          );
        },
        onSuccess: () => {
          showFloatingMessage(
            <>
              The user <strong>{user.name}</strong> has been successfully added
              to the team <strong>{data.team.label}</strong>.
            </>,
            {
              state: "success",
              noIcon: true,
            },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  });

  const getTeamOptions = () => {
    const teamOptions: TeamOption[] = [];

    for (const team of allTeams.data ?? []) {
      const canAddUserToTeam = role.isAdmin || team.roles.includes(Role.Owner);

      if (!canAddUserToTeam) {
        continue;
      }

      const existingUserTeamRoles = userTeams.data?.find(
        (userTeam) => userTeam.id === team.id,
      )?.roles;

      const isUserAlreadyMember = Boolean(existingUserTeamRoles?.length);

      teamOptions.push({
        label: team.name,
        value: team.id,
        isDisabled: isUserAlreadyMember,
      });
    }

    const sortedOptions = teamOptions.sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    return sortedOptions;
  };

  return (
    <form onSubmit={(e) => void onSubmit(e)}>
      <Grid className={styles.modal} gap={24}>
        <Input label="User" disabled placeholder={user.name} />

        <div>
          <Controller
            control={control}
            name="role"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                label="Role"
                options={roleOptions}
                placeholder="Select a role"
                feedback={fieldState.error?.message ?? ""}
                state={
                  fieldState.isTouched && fieldState.error ? "alert" : undefined
                }
              />
            )}
          />

          <TeamRoleDescriptionTable />
        </div>

        <Controller
          control={control}
          name="team"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              label="Team"
              options={getTeamOptions()}
              isLoading={userTeams.isPending || allTeams.isPending}
              placeholder="Select a team"
              feedback={fieldState.error?.message ?? ""}
              state={
                fieldState.isTouched && fieldState.error ? "alert" : undefined
              }
            />
          )}
        />

        <Inline>
          <Inline.Separator />

          <Button state="neutral" onClick={() => handleModal({})}>
            Cancel
          </Button>

          <Button
            type="submit"
            state={formState.isValid ? "neutral" : "inactive"}
            variant="filled"
          >
            Add user
          </Button>
        </Inline>
      </Grid>
    </form>
  );
};
