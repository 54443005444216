import { Button, useBreakpoint } from "@intility/bifrost-react";
import { parseAsStringLiteral, useQueryState } from "nuqs";
import { Link } from "react-router";
import wayfinderDark from "~/assets/wayfinder-dark.svg";
import wayfinderLight from "~/assets/wayfinder-light.svg";
import wayfinderMobileDark from "~/assets/wayfinder-mobile-dark.svg";
import wayfinderMobileLight from "~/assets/wayfinder-mobile-light.svg";
import { ColorSchemedImage } from "~/components/ColorSchemedImage";
import PageBanner from "~/components/PageBanner";
import styles from "./helpCenter.module.css";
import ArticlesSection from "../components/articles/ArticlesSection";
import SupportSection from "../components/support/SupportSection";

const tabs = ["articles", "support"] as const;

const HelpCenterPage = () => {
  const [selectedTab] = useQueryState(
    "tab",
    parseAsStringLiteral(tabs).withDefault("articles"),
  );

  const isMobile = useBreakpoint(null, "small");

  return (
    <div className={styles.content}>
      <PageBanner
        title="Help center"
        subTitle="Read articles and look up contact information"
        content={
          <ColorSchemedImage
            className={styles.pageBannerIllustration}
            srcLight={isMobile ? wayfinderMobileLight : wayfinderLight}
            srcDark={isMobile ? wayfinderMobileDark : wayfinderDark}
            height={120}
            alt="Illustration of a woman looking around"
          />
        }
        hideGradient={isMobile}
      />

      <Button.Group className={styles.tabButtonGroup}>
        <Link to="?tab=articles" className={styles.tabLink}>
          <Button active={selectedTab.includes("articles")}>Articles</Button>
        </Link>

        <Link to="?tab=support" className={styles.tabLink}>
          <Button active={selectedTab.includes("support")}>Support</Button>
        </Link>
      </Button.Group>

      {selectedTab === "articles" ? <ArticlesSection /> : <SupportSection />}
    </div>
  );
};

export default HelpCenterPage;
