import { Accordion, Inline, Menu } from "@intility/bifrost-react";
import styles from "./createClusterMenu.module.css";
import type { NewClusterForm, NewClusterStep } from "~/types";
import { CreateClusterMenuSteps } from "./CreateClusterMenuSteps";
import type { NewClusterStepName } from "../../routes/NewCluster";

const CreateClusterMenu = ({
  steps,
  activeStep,
  handleSetActiveStep,
  formData,
  visitedSteps,
}: {
  steps: NewClusterStep[];
  activeStep: NewClusterStep;
  handleSetActiveStep: (activeStep: NewClusterStep) => void;
  formData: NewClusterForm;
  visitedSteps: NewClusterStepName[];
}) => {
  return (
    <div className={styles.content}>
      <h5 className={`bf-h5 ${styles.title}`}>Create new cluster</h5>

      <Accordion className="to-large">
        <Accordion.Item
          title={
            <Inline>
              <Inline.Stretch>{activeStep.title}</Inline.Stretch>
              <span className="bfc-base-2">
                Step {activeStep.id + 1}/{steps.length}
              </span>
            </Inline>
          }
        >
          <Menu>
            <CreateClusterMenuSteps
              steps={steps}
              activeStep={activeStep}
              handleSetActiveStep={handleSetActiveStep}
              formData={formData}
              visitedSteps={visitedSteps}
            />
          </Menu>
        </Accordion.Item>
      </Accordion>

      <Menu className="from-large">
        <CreateClusterMenuSteps
          steps={steps}
          activeStep={activeStep}
          handleSetActiveStep={handleSetActiveStep}
          formData={formData}
          visitedSteps={visitedSteps}
        />
      </Menu>
    </div>
  );
};

export default CreateClusterMenu;
