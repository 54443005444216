import {
  faArrowRotateRight,
  faFilter,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, Checkbox, Dropdown, Icon } from "@intility/bifrost-react";
import styles from "./FilterDropdown.module.css";
import {
  articleFilterOptions,
  useArticleCategories,
} from "../../hooks/useArticleCategories";

export const FilterDropdown = () => {
  return (
    <Dropdown
      content={<DropdownContent />}
      noPadding
      className={styles.dropdown}
    >
      <Button variant="flat" state="neutral">
        <Icon icon={faFilter} marginRight />
        Filter
      </Button>
    </Dropdown>
  );
};

const DropdownContent = () => {
  const [categories, setCategories] = useArticleCategories();

  return (
    <div>
      <div className={styles.headerContent}>
        <span className={styles.contentLabel}>Filter</span>

        <Button
          variant="flat"
          state="neutral"
          small
          onClick={() => void setCategories(null)}
        >
          <Icon icon={faArrowRotateRight} marginRight />
          Reset
        </Button>
      </div>

      <div className={styles.filterOptions}>
        {articleFilterOptions.map((category) => (
          <Checkbox
            key={category.value}
            label={category.label}
            checked={categories?.includes(category.value)}
            onClick={() => {
              const isChecked = Boolean(categories?.includes(category.value));

              const newCategories = isChecked
                ? categories?.filter((c) => c !== category.value)
                : [...(categories ?? []), category.value];

              if (newCategories?.length === 0 || !newCategories) {
                void setCategories(null);
              } else {
                void setCategories(newCategories);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};
