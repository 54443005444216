import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, useFloatingMessage } from "@intility/bifrost-react";
import { useState } from "react";
import { NodeSpecPreset, type Cluster, type NewClusterForm } from "~/types";
import SpecificationsStep from "../newCluster/SpecificationsStep";
import styles from "./EditClusterForm.module.css";
import { useEditClusterSpec } from "../../api/editClusterSpec";
import { useNavigate } from "react-router";
import { getPresetSpecs } from "../../utils/getPresetSpecs";

export type EditClusterForm = Pick<NewClusterForm, "nodeCount" | "preset">;

export const EditClusterForm = ({ cluster }: { cluster: Cluster }) => {
  const editClusterSpec = useEditClusterSpec();
  const navigate = useNavigate();
  const { showFloatingMessage } = useFloatingMessage();

  const existingNodePool = cluster.nodePools.at(0);
  const existingReplicas = existingNodePool?.replicas;
  const existingPreset = existingNodePool?.preset;

  const [formData, setFormData] = useState<EditClusterForm>({
    nodeCount: existingReplicas ?? 2,
    preset: existingPreset ?? NodeSpecPreset.Minimal,
  });

  const handleConfirmSpec = () => {
    if (editClusterSpec.isPending) {
      return;
    }

    editClusterSpec.mutate(
      {
        clusterId: cluster.id,
        dto: {
          nodePools: [
            {
              replicas: formData.nodeCount,
              preset: formData.preset,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          const oldValues = existingNodePool && {
            nodeCount: existingNodePool.replicas,
            preset: existingNodePool.preset,
          };

          showFloatingMessage(
            <SuccessMessage
              clusterName={cluster.name}
              oldValues={existingNodePool ? oldValues : undefined}
              newValues={formData}
            />,
            {
              noIcon: true,
              state: "success",
            },
          );

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigate(`/clusters/${cluster.id}`);
        },
        onError: () => {
          showFloatingMessage(
            <>
              We were unable to edit the specifications of the cluster. Please
              try again later.
            </>,
            {
              noIcon: true,
              state: "alert",
            },
          );
        },
      },
    );
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formContent}>
        <SpecificationsStep formData={formData} setFormData={setFormData} />
      </div>

      <div className={styles.footerContainer}>
        <Button
          state="neutral"
          variant="filled"
          onClick={() => handleConfirmSpec()}
        >
          Confirm changes
          <Icon icon={faCheck} marginLeft />
        </Button>
      </div>
    </div>
  );
};

const SuccessMessage = ({
  clusterName,
  oldValues,
  newValues,
}: {
  clusterName: string;
  oldValues: EditClusterForm | undefined;
  newValues: EditClusterForm;
}) => {
  const generateChangesString = () => {
    let changes = "";

    const nodeCountChanged = oldValues?.nodeCount !== newValues.nodeCount;
    const presetChanged = oldValues?.preset !== newValues.preset;

    if (nodeCountChanged) {
      changes += `${newValues.nodeCount} nodes`;
    }

    if (presetChanged) {
      if (nodeCountChanged) {
        changes += " with ";
      }

      const presetSpecs = getPresetSpecs(newValues.preset);
      const cpuCount = presetSpecs.CPU;
      const ramAmount = presetSpecs.RAM;

      changes += `${cpuCount} CPU and ${ramAmount} RAM`;
    }

    return changes;
  };

  const changes = generateChangesString();

  return (
    <>
      The specifications of cluster <strong>{clusterName}</strong> were
      successfully changed to {changes}.
    </>
  );
};
