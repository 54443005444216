import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";

const deleteCluster = async (id: string) => {
  return api.delete(`api/v1/clusters/${id}`, { prefixUrl: API.IDP.url });
};

export const useDeleteCluster = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteCluster(id),
    onSuccess: (data, id) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.Clusters] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Cluster, id],
      });
    },
  });
};
