import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { Cluster } from "~/types";

const getCluster = (clusterId: string) => {
  return api
    .get(`api/v1/clusters/${clusterId}`, { prefixUrl: API.IDP.url })
    .json<Cluster>();
};

export const clusterQuery = (clusterId: string) => ({
  queryKey: [QueryKey.Cluster, clusterId],
  queryFn: () => getCluster(clusterId),
  enabled: !!clusterId,
});

export const useCluster = (clusterId: string) => {
  return useQuery(clusterQuery(clusterId));
};
