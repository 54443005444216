import React from "react";

type ColorSchemedImageProps = {
  srcLight: string;
  srcDark: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const ColorSchemedImage = ({
  srcLight,
  srcDark,
  alt,
  className,
  ...rest
}: ColorSchemedImageProps) => {
  return (
    <picture className={className}>
      <source srcSet={srcLight} media="(prefers-color-scheme: light)" />
      <source srcSet={srcDark} media="(prefers-color-scheme: dark)" />

      <img className={className} src={srcDark} alt={alt} {...rest} />
    </picture>
  );
};
