import { parseAsArrayOf, parseAsStringLiteral, useQueryState } from "nuqs";

export const ArticleCategory = {
  LEARN_MORE: {
    label: "Learn more" as const,
    value: "learn" as const,
  },
  GUIDE: {
    label: "Guide" as const,
    value: "guide" as const,
  },
};

export type ArticleCategory =
  (typeof ArticleCategory)[keyof typeof ArticleCategory];

export const articleFilterOptions = [
  { label: ArticleCategory.GUIDE.label, value: ArticleCategory.GUIDE.value },
  {
    label: ArticleCategory.LEARN_MORE.label,
    value: ArticleCategory.LEARN_MORE.value,
  },
];

const articleCategories = articleFilterOptions.map(
  (category) => category.value,
);

export type ArticleCategoryValue = (typeof articleCategories)[number];

export const useArticleCategories = () => {
  return useQueryState(
    "categories",
    parseAsArrayOf(parseAsStringLiteral(articleCategories)),
  );
};
