import { Modal } from "@intility/bifrost-react";
import React, {
  createContext,
  useState,
  type PropsWithChildren,
  type ReactElement,
} from "react";

type HandleModalParams = {
  content?: ReactElement;
  header?: ReactElement;
};

interface ModalContextProps {
  isOpen?: boolean;
  handleModal: (params: HandleModalParams) => void;
  modalContent?: HandleModalParams["content"];
  modalHeader?: HandleModalParams["header"];
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  handleModal: () => {},
  modalContent: <></>,
  modalHeader: <></>,
});

const ModalProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement>(<></>);
  const [modalHeader, setModalHeader] = useState(<></>);

  /**
   * Opening a modal:
   *
   * ```javascript
   * const { handleModal } = useContext(ModalContext);
   *
   * <button
   *   onClick={() => {
   *     handleModal({
   *       content: <div>Modal content</div>,
   *       header: <div>Modal header</div>,
   *     });
   *   }}
   * >
   *   Open modal
   * </button>
   * ```
   *
   * Closing a modal programmatically:
   *
   * ```javascript
   * const { handleModal } = useContext(ModalContext);
   *
   * handleModal({});
   * ```
   */
  const handleModal = ({
    content = <></>,
    header = <></>,
  }: HandleModalParams) => {
    setIsOpen((old) => !old);
    setModalContent(content);
    setModalHeader(header);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        modalContent,
        handleModal,
        modalHeader,
      }}
    >
      <Modal
        onRequestClose={() => setIsOpen(false)}
        isOpen={isOpen}
        header={modalHeader}
      >
        {modalContent}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
