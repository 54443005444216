import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { MemberType, QueryKey } from "~/constants";

const removeClusterAccess = ({
  clusterId,
  memberId,
  memberType,
}: RemoveClusterAccessParams) => {
  const memberIdPrefix = `${memberType}:`;

  return api
    .delete(
      `api/v1/clusters/${clusterId}/members/${memberIdPrefix}${memberId}`,
      { prefixUrl: API.IDP.url },
    )
    .json();
};

type RemoveClusterAccessParams = {
  clusterId: string;
  memberId: string;
  memberType: MemberType;
};

export const useRemoveClusterAccess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      clusterId,
      memberId,
      memberType,
    }: RemoveClusterAccessParams) =>
      removeClusterAccess({ clusterId, memberId, memberType }),
    onSuccess: (data, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.UserClusters] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.Clusters] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ClusterMembers, variables.clusterId],
      });
    },
  });
};
