import { useBreakpoint } from "@intility/bifrost-react";
import type { PaginationState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { DataTable } from "~/components/table/DataTable";
import { useSearch } from "~/hooks/useSearch";
import type { TeamDTO } from "../../api/getTeam";
import { useTeams } from "../../api/getTeams";
import { useRole } from "../../hooks/useRole";
import styles from "./TeamsTable.module.css";
import { teamColumns } from "./TeamsTableColumns";
import { Role } from "~/constants";

export const TeamsTable = () => {
  const teams = useTeams();
  const toSmall = useBreakpoint(null, "small");

  const [search] = useSearch();
  const [role] = useRole();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 8,
  });

  const columnFilters = useMemo(() => {
    const filters = [];

    if (role) {
      filters.push({ id: "role", value: role });
    }

    return filters;
  }, [role]);

  return (
    <DataTable
      className={styles.table}
      data={teams.data}
      isLoading={teams.isPending}
      columns={teamColumns}
      state={{
        globalFilter: search,
        columnFilters,
        pagination,
      }}
      initialState={{
        sorting: [
          { id: "role", desc: true },
          { id: "name", desc: false },
        ],
      }}
      onPaginationChange={setPagination}
      noBorder
      noResultsText={getNoResultsText(search, role)}
      ExpandedRowComponent={toSmall ? ExpandedTeam : undefined}
    />
  );
};

const getNoResultsText = (
  searchValue: string | null,
  selectedRole: Role | null,
) => {
  switch (true) {
    case Boolean(searchValue):
      return `No results found for "${searchValue}".`;
    case selectedRole === Role.Owner:
      return `You are not the owner of any team.`;
    case selectedRole === Role.Member:
      return `You do not have a member role for any team.`;
    default:
      return;
  }
};

const ExpandedTeam = ({ rowData }: { rowData: TeamDTO }) => {
  return <>{rowData.description}</>;
};
