import { Badge, Grid, Section } from "@intility/bifrost-react";
import { Link } from "react-router";
import manageClustersCli from "~/assets/images/manage-clusters-cli.png";
import manageClustersPortal from "~/assets/images/home/manage-clusters-portal.png";
import manageTeams from "~/assets/images/articles/manage-teams.png";
import styles from "./articlesSection.module.css";

interface ArticlesSectionProps {
  className?: string;
}

const ArticlesSection = ({ className }: ArticlesSectionProps) => {
  return (
    <Section className={className}>
      <Link to="/helpcenter?tab=articles" className="bf-neutral-link">
        <Section.Header arrow>
          <span className="bf-neutral-link-text">Articles</span>
        </Section.Header>
      </Link>

      <Section.Content>
        <Grid small={3}>
          {articles.map((article) => {
            return (
              <a
                key={article.title}
                href={article.link}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.article}>
                  <img src={article.image} alt={article.imageAlt} />

                  <Badge pill state="attn" className={styles.articleBadge}>
                    {article.badgeText}
                  </Badge>

                  <span className={styles.articleLabel}>{article.title}</span>
                </div>
              </a>
            );
          })}
        </Grid>
      </Section.Content>
    </Section>
  );
};

export default ArticlesSection;

const articles = [
  {
    title: "Manage clusters with CLI (indev)",
    image: manageClustersCli,
    imageAlt: "managing clusters with cli",
    link: "https://article.intility.com/51ec0d96-220b-4e66-402b-08dc346b24fd",
    badgeText: "Guide",
  },
  {
    title: "Manage clusters using the portal",
    image: manageClustersPortal,
    imageAlt: "managing clusters with portal",
    link: "https://article.intility.com/7f9cc811-02f7-47f0-39d8-08dc85ed70aa",
    badgeText: "Guide",
  },
  {
    title: "Manage teams using the portal",
    image: manageTeams,
    imageAlt: "",
    link: "https://article.intility.com/f55e288a-99f9-41e4-9bb2-08dd1b6aa581",
    badgeText: "Guide",
  },
];
