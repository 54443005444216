import { Breakpoint } from "@intility/bifrost-react";
import styles from "./pageBanner.module.css";
import { cn } from "~/utils/clsx";

type PageBannerProps = {
  title: string;
  subTitle: string;
  content?: React.ReactNode;
  hideGradient?: boolean;
  className?: string;
};

const PageBanner: React.FC<PageBannerProps> = ({
  title,
  subTitle,
  content,
  hideGradient,
  className,
}) => {
  return (
    <div
      className={cn(styles.banner, className, {
        [styles.gradient]: !hideGradient,
      })}
    >
      <div className={styles.titleSection}>
        <h2 className="bf-h2">{title}</h2>
        <Breakpoint from="small">
          <div className="bf-label-description">{subTitle}</div>
        </Breakpoint>
      </div>

      {content}
    </div>
  );
};

export default PageBanner;
