import { z } from "zod";
import { ClusterRole, MemberType } from "~/constants";
import type { Cluster } from "~/types";
import { useClusterAssignables } from "../../api/getClusterAssignables";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useFloatingMessage,
  Grid,
  Input,
  Inline,
  Button,
  Message,
  Icon,
  Modal,
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { useForm, Controller } from "react-hook-form";
import styles from "./AddClusterAccessModal.module.css";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useAddClusterAccess } from "../../api/addClusterAccess";
import { useRef, useState } from "react";
import { useClickOutside } from "~/hooks/useClickOutside";

const roleOptions = [
  { label: "Reader", value: ClusterRole.Reader },
  { label: "Admin", value: ClusterRole.Admin },
];

const addClusterAccessSchema = z.object({
  clusterId: z.string(),
  role: z
    .object(
      {
        label: z.string(),
        value: z.nativeEnum(ClusterRole),
      },
      { required_error: "Required" },
    )
    .required(),
  members: z.array(
    z.object(
      {
        label: z.string(),
        value: z.string(),
        memberType: z.nativeEnum(MemberType),
      },
      { required_error: "Required" },
    ),
  ),
});

type AddClusterAccessSchema = z.infer<typeof addClusterAccessSchema>;

type ClusterAssignableOption = AddClusterAccessSchema["members"][number];

interface AddClusterAccessModalProps {
  cluster: Cluster;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const AddClusterAccessModal = ({
  cluster,
  isOpen,
  setIsOpen,
}: AddClusterAccessModalProps) => {
  const addClusterAccess = useAddClusterAccess();
  const clusterAssignables = useClusterAssignables(cluster.id);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const [isMembersMenuOpen, setIsMembersMenuOpen] = useState(false);

  const { control, formState, handleSubmit, reset } =
    useForm<AddClusterAccessSchema>({
      resolver: zodResolver(addClusterAccessSchema),
      defaultValues: { clusterId: cluster.id },
    });

  const { showFloatingMessage } = useFloatingMessage();

  /**
   * Close the select menu when clicking outside of the select menu but within the modal
   * Modal's onRequestClose closes the select menu when clicking outside of the modal
   */
  useClickOutside(selectRef, (event) => {
    const modalContentEl = modalContentRef.current;

    if (!modalContentEl || !isMembersMenuOpen) return;

    const clickedWithinModal = modalContentEl.contains(event.target as Node);

    if (clickedWithinModal) {
      toggleMembersMenuIsOpen();
    }
  });

  const toggleMembersMenuIsOpen = () => {
    setIsMembersMenuOpen((value) => !value);

    const selectEl = selectRef.current;
    if (!selectEl) return;

    if (isMembersMenuOpen) {
      selectEl.blur();
    } else {
      selectEl.focus();
    }
  };

  const closeModal = () => {
    reset();
    setIsOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    if (addClusterAccess.isPending) {
      return;
    }

    addClusterAccess.mutate(
      {
        clusterId: data.clusterId,
        dto: {
          values: data.members.map((member) => ({
            subject: { id: member.value, type: member.memberType },
            roles: [data.role.value],
          })),
        },
      },
      {
        onError: () => {
          showFloatingMessage(
            <>
              Failed to grant access to <strong>{cluster.name}</strong> for one
              or more members. Please try again.
            </>,
            {
              noIcon: true,
              state: "alert",
            },
          );
        },
        onSuccess: () => {
          showFloatingMessage(
            <>
              Access to the cluster <strong>{cluster.name}</strong> has been
              successfully granted.
            </>,
            {
              noIcon: true,
              state: "success",
            },
          );
        },
        onSettled: () => {
          closeModal();
        },
      },
    );
  });

  const getClusterAssignableOptions = () => {
    const teamOptions: ClusterAssignableOption[] = [];
    const userOptions: ClusterAssignableOption[] = [];

    const sortedAssignables = clusterAssignables.data?.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    for (const assignable of sortedAssignables ?? []) {
      const { assignedRoles } = assignable;

      if (!assignedRoles.length) {
        const option: ClusterAssignableOption = {
          label: assignable.name,
          value: assignable.id,
          memberType: assignable.type,
        };

        if (assignable.type === MemberType.Team) {
          teamOptions.push(option);
        } else {
          userOptions.push(option);
        }
      }
    }

    return [
      { label: "Teams", options: teamOptions },
      { label: "Users", options: userOptions },
    ];
  };

  const clusterAssignableOptions = getClusterAssignableOptions();

  return (
    <Modal
      isOpen={isOpen}
      noPadding
      onRequestClose={() => {
        if (isMembersMenuOpen) {
          toggleMembersMenuIsOpen();
        } else {
          closeModal();
        }
      }}
    >
      <div ref={modalContentRef} className={styles.container}>
        <header className="bf-modal-header">Give cluster access</header>

        <form onSubmit={(e) => void onSubmit(e)}>
          <Grid gap={24}>
            <Input label="Cluster" disabled placeholder={cluster.name} />

            <div>
              <Controller
                control={control}
                name="role"
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    label="Role"
                    placeholder="Select role"
                    options={roleOptions}
                    feedback={fieldState.error?.message ?? ""}
                    state={
                      fieldState.isTouched && fieldState.error
                        ? "alert"
                        : undefined
                    }
                  />
                )}
              />

              <Message
                className={styles.roleDescriptionMessage}
                state="neutral"
              >
                <div className={styles.roleDescriptionTitle}>
                  <Icon icon={faInfoCircle} /> Role description
                </div>

                <p>
                  <span className={styles.roleEmphasis}>Reader</span>: is
                  assigned{" "}
                  <a
                    href="https://article.intility.com/69963862-be3f-4588-8c3c-08dce9d0db05#:custom-roles-managed-by-intility"
                    target="_blank"
                    rel="noreferrer"
                    className="bf-link"
                  >
                    reader
                  </a>{" "}
                  access for the cluster
                  <br />
                  <span className={styles.roleEmphasis}>Admin</span>: is
                  assigned{" "}
                  <a
                    href="https://article.intility.com/69963862-be3f-4588-8c3c-08dce9d0db05#:custom-roles-managed-by-intility"
                    target="_blank"
                    rel="noreferrer"
                    className="bf-link"
                  >
                    admin
                  </a>{" "}
                  access for the cluster
                </p>
              </Message>
            </div>

            {/* 
              Setting the ref on the <Select> component causes an error when useClickOutside is triggered - "ref.current.contains is not a function"
              Therefore, we wrap the <Select> component in a <div> and set the ref on the <div> instead              
            */}
            <div ref={selectRef}>
              <Controller
                control={control}
                name="members"
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    label="Member(s)"
                    placeholder="Select one or more"
                    loading={clusterAssignables.isPending}
                    options={clusterAssignableOptions}
                    feedback={fieldState.error?.message ?? ""}
                    menuIsOpen={isMembersMenuOpen}
                    onMenuOpen={() => setIsMembersMenuOpen(true)}
                    isMulti
                    state={
                      fieldState.isTouched && fieldState.error
                        ? "alert"
                        : undefined
                    }
                  />
                )}
              />
            </div>

            <Inline>
              <Inline.Separator />

              <Button state="neutral" onClick={() => closeModal()}>
                Cancel
              </Button>

              <Button
                type="submit"
                variant="filled"
                state={formState.isValid ? "neutral" : "inactive"}
              >
                Give access
              </Button>
            </Inline>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};
