import type { ClusterRole } from "./constants";

export type Cluster = {
  id: string;
  name: string;
  consoleUrl: string;
  status: {
    deployment: {
      active: boolean;
      failed: boolean;
    };
    ready: {
      message: string;
      reason: string;
      status: boolean;
    };
  };
  nodePools: NodePool[];
  roles: ClusterRole[];
};

export type NewClusterStep = {
  id: number;
  name: "clusterName" | "specifications" | "summary";
  title: string;
  isComplete: (formData: NewClusterForm) => boolean;
};

export type NewClusterForm = {
  clusterName: string;
  clusterNameSuffix: string;
  nodeCount: number;
  preset: NodeSpecPreset;
};

export type NodePool = {
  autoscalingEnabled?: boolean;
  compute?: {
    cores: number;
    memory: string;
  };
  maxCount?: number;
  minCount?: number;
  name?: string;
  preset: NodeSpecPreset;
  replicas: number;
};

export enum NodeSpecPreset {
  Minimal = "minimal",
  Balanced = "balanced",
  Performance = "performance",
}
