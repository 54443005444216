import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, type ClusterRole, type MemberType } from "~/constants";

export type ClusterMemberDTO = {
  subject: {
    id: string;
    type: MemberType;
    name: string;
    details: string;
  };
  roles: ClusterRole[];
};

const getClusterMembers = (clusterId: string) => {
  return api
    .get(`api/v1/clusters/${clusterId}/members`, { prefixUrl: API.IDP.url })
    .json<ClusterMemberDTO[]>();
};

export const useClusterMembers = (clusterId: string) => {
  return useQuery({
    queryKey: [QueryKey.ClusterMembers, clusterId],
    queryFn: () => getClusterMembers(clusterId),
  });
};
