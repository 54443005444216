import { type RowData } from "@tanstack/react-table";
import { DataTable } from "~/components/table/DataTable";
import { MemberType } from "~/constants";
import { useUserTeams } from "../../api/getUserTeams";
import type { UserDTO } from "../../api/getUsers";
import { NoResults } from "../NoResults";
import { userTeamColumns } from "./UserTeamsTableColumns";
import styles from "./UserTeamsTable.module.css";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    member?: {
      id: string;
      name: string;
      memberType: MemberType;
    };
  }
}

export const UserTeamsTable = ({
  user,
  teamFilter,
}: { user: UserDTO; teamFilter: string }) => {
  const userTeams = useUserTeams(user.id);

  if (userTeams.data?.length === 0 && !userTeams.isPending) {
    return <NoResults message="This user is not a member of any teams" />;
  }

  return (
    <DataTable
      className={styles.table}
      columns={userTeamColumns}
      data={userTeams.data}
      isLoading={userTeams.isPending}
      noBorder
      noHeader
      state={{ globalFilter: teamFilter }}
      initialState={{
        sorting: [
          { id: "role", desc: true },
          { id: "name", desc: false },
        ],
      }}
      meta={{
        skeletonRows: 3,
        member: {
          id: user.id,
          name: user.name,
          memberType: "user",
        },
      }}
    />
  );
};
