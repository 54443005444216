import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Grid, Input } from "@intility/bifrost-react";
import { useState } from "react";
import manageClusterCliImg from "~/assets/images/manage-clusters-cli.png";
import highAvailabilityImg from "~/assets/images/articles/high-availability.jpg";
import manageClusterPortalImg from "~/assets/images/articles/manage-clusters-portal.png";
import managedClusterImg from "~/assets/images/articles/managed-cluster.png";
import monitoringImg from "~/assets/images/articles/monitoring.jpg";
import operationalResponsibilityImg from "~/assets/images/articles/operational-responsibility.jpg";
import securityImg from "~/assets/images/articles/security.jpg";
import supportImg from "~/assets/images/articles/support.jpg";
import kubernetesRbacImg from "~/assets/images/articles/kubernetes-rbac.jpg";
import clusterLoginImg from "~/assets/images/articles/cluster-login.png";
import whoAreWeImg from "~/assets/images/articles/who-are-we.png";
import styles from "./articlesSection.module.css";
import manageTeams from "~/assets/images/articles/manage-teams.png";
import { FilterDropdown } from "./FilterDropdown";
import ArticleCard from "./ArticleCard";
import {
  ArticleCategory,
  useArticleCategories,
  type ArticleCategoryValue,
} from "../../hooks/useArticleCategories";

const ArticlesSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const [categories] = useArticleCategories();

  const filterArticles = (categories: ArticleCategoryValue[] | null) => {
    const filteredArticles = [];

    for (const article of articles) {
      const matchesCategory = categories
        ? categories.includes(article.category)
        : true;

      const matchesSearch = article.title
        .toLowerCase()
        .includes(searchValue.toLowerCase());

      if (matchesCategory && matchesSearch) {
        filteredArticles.push(article);
      }
    }

    return filteredArticles;
  };

  const filteredArticles = filterArticles(categories);

  return (
    <div className={styles.content}>
      <div className={styles.introBanner}>
        <div className={styles.inputSection}>
          <Input
            className={styles.searchInput}
            label="search"
            hideLabel
            clearable
            placeholder="Search"
            icon={faSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            iconButton
            rightIcon
          />

          <FilterDropdown />
        </div>
      </div>

      <Grid gap={16} large={2}>
        {filteredArticles.map((article) => (
          <ArticleCard
            key={article.title}
            imgSrc={article.imgSrc}
            imgAlt={article.imgAlt}
            title={article.title}
            ingress={article.ingress}
            href={article.href}
            category={article.category}
          />
        ))}
      </Grid>
    </div>
  );
};

export default ArticlesSection;

const articles = [
  {
    imgSrc: whoAreWeImg,
    imgAlt: "",
    title: "Who are we?",
    ingress:
      "Our goal is to help you spend more time on development and less time on everything else. We take care of the technical stuff, so you can focus on the creative.",
    href: "https://article.intility.com/d6ffbd1d-b121-431c-460f-08dc846ac68b",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: manageClusterPortalImg,
    imgAlt: "",
    title: "Manage clusters in the portal",
    ingress:
      "This guide will help you manage your cluster effectively using the portal",
    href: "https://article.intility.com/7f9cc811-02f7-47f0-39d8-08dc85ed70aa",
    category: ArticleCategory.GUIDE.value,
  },
  {
    imgSrc: manageClusterCliImg,
    imgAlt: "",
    title: "Manage clusters with the CLI",
    ingress:
      "This guide will help you understand how to create, list, and delete clusters using the command-line tool and indev.",
    href: "https://article.intility.com/51ec0d96-220b-4e66-402b-08dc346b24fd",
    category: ArticleCategory.GUIDE.value,
  },
  {
    imgSrc: securityImg,
    imgAlt: "",
    title: "Security",
    ingress:
      "We take responsibility for ensuring your Kubernetes infrastructure is secure and continuously monitor to handle security incidents.",
    href: "https://article.intility.com/a2771fb2-5f88-46cc-dc7f-08dc8467abab",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: supportImg,
    imgAlt: "",
    title: "Support",
    ingress:
      "Intility ensures fast and efficient support for development teams through the integration of collaboration tools like Slack and Microsoft Teams.",
    href: "https://article.intility.com/c09c78f9-1f0b-42e6-9adc-08dc3464654f",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: highAvailabilityImg,
    imgAlt: "",
    title: "High Availability",
    ingress:
      "High Availability (HA) ensures that your applications are robust and always available, even when the infrastructure fails.",
    href: "https://article.intility.com/4e332997-c40b-4d71-4611-08dc846ac68b",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: monitoringImg,
    imgAlt: "",
    title: "Monitoring",
    ingress:
      "Intility is responsible for 24/7 monitoring and managing alarms on all resources, such as the OpenShift cluster and the Argo CD instance, that you create on the Developer Platform.",
    href: "https://article.intility.com/a52ff4bb-6c2b-4daa-9add-08dc3464654f",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: managedClusterImg,
    imgAlt: "",
    title: "Managed Cluster",
    ingress:
      "With our managed cluster, you get a complete solution that lets you focus on development while we take care of all the technical aspects.",
    href: "https://article.intility.com/6640b62d-babc-4a3b-12ab-08dc8469dd1f",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: operationalResponsibilityImg,
    imgAlt: "",
    title: "Intility's Operational Responsibility and Self-Service",
    ingress:
      "The Intility Developer Platform is designed to handle the increasing demands for automation, scalability, and abstraction in Kubernetes.",
    href: "https://article.intility.com/4e280e53-515e-48df-be7f-08dc366a584a",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgSrc: kubernetesRbacImg,
    imgAlt: "",
    title: "Best practice for RBAC in Kubernetes clusters",
    ingress:
      "While ownership of one's own Kubernetes cluster has a number of practical benefits, there are a few things to note when it comes to RBAC to keep the cluster secure and stable.",
    href: "https://article.intility.com/69963862-be3f-4588-8c3c-08dce9d0db05",
    category: ArticleCategory.LEARN_MORE.value,
  },
  {
    imgAlt: "",
    imgSrc: clusterLoginImg,
    title: "Logging into a cluster on the command-line (kubectl / oc)",
    ingress:
      "This guide shows how to use local tools like kubectl with Intility Managed Clusters.",
    href: "https://article.intility.com/9feee2f6-2e98-4dcb-8c64-08dce9d0db05",
    category: ArticleCategory.GUIDE.value,
  },
  {
    imgAlt: "",
    imgSrc: manageTeams,
    title: "Manage teams using the portal",
    ingress:
      "This guide will help you get started with the Teams feature in Developer Platform.",
    href: "https://article.intility.com/f55e288a-99f9-41e4-9bb2-08dd1b6aa581",
    category: ArticleCategory.GUIDE.value,
  },
];
