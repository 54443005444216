import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import type { TeamDTO } from "../../api/getTeam";
import { Button, Icon, Input } from "@intility/bifrost-react";
import { AddTeamMembersModal } from "./AddTeamMembersModal";
import { type PropsWithChildren, useState } from "react";
import { TeamMembersTable } from "./TeamMembersTable";
import styles from "./TeamMembersSection.module.css";
import { useTeamMembers } from "../../api/getTeamMembers";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { useRole } from "~/hooks/useRole";
import { Role } from "~/constants";

interface TeamMembersSectionProps {
  team: TeamDTO;
}

export const TeamMembersSection = ({ team }: TeamMembersSectionProps) => {
  const teamMembers = useTeamMembers(team.id);

  const [memberFilter, setMemberFilter] = useState("");

  const role = useRole();
  const isTeamOwner = team.roles.includes(Role.Owner);
  const isOrgAdmin = role.isAdmin;
  const shouldShowAddMemberButton =
    teamMembers.isSuccess && (isTeamOwner || isOrgAdmin);

  if (teamMembers.isError) {
    return (
      <Layout team={team} showButton={shouldShowAddMemberButton}>
        <UnavailableInfoError className={styles.errorContainer} />
      </Layout>
    );
  }

  return (
    <Layout team={team} showButton={shouldShowAddMemberButton}>
      <div className={styles.searchInputWrapper}>
        <Input
          className={styles.memberSearchInput}
          label="Search"
          hideLabel
          placeholder="Look up member"
          rightIcon
          icon={faSearch}
          value={memberFilter}
          onChange={(e) => setMemberFilter(e.target.value)}
        />
      </div>

      <TeamMembersTable team={team} filterValue={memberFilter} />
    </Layout>
  );
};

const Layout = ({
  children,
  team,
  showButton = true,
}: PropsWithChildren<{ team: TeamDTO; showButton?: boolean }>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.infoHeader}>
        <div>
          <h5 className="bf-h5">Members of this team</h5>
          <p className={styles.tabDescription}>
            List of members of this team and their role within it.
          </p>
        </div>

        {showButton && (
          <Button
            className={styles.addToTeamButton}
            variant="filled"
            state="neutral"
            onClick={() => setIsOpen(true)}
          >
            <Icon marginRight icon={faPlus} />
            Add to team
          </Button>
        )}
      </div>

      {children}

      <AddTeamMembersModal team={team} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
