import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, Role } from "~/constants";

type MeDTO = {
  name: string;
  email: string;
  id: string;
  organizationId: string;
  organizationName: string;
  organizationRoles: Role[];
};

const getMe = () => {
  return api.get(`api/v1/me`, { prefixUrl: API.IDP.url }).json<MeDTO>();
};

export const useMe = () => {
  return useQuery({
    queryFn: getMe,
    queryKey: [QueryKey.Me],
    staleTime: 1000 * 60 * 5,
  });
};
