import {
  Button,
  Grid,
  Icon,
  Inline,
  Input,
  useFloatingMessage,
} from "@intility/bifrost-react";

import { useContext, useState } from "react";
import { ModalContext } from "~/context/ModalContext";
import { useDeleteCluster } from "../../api/deleteCluster";
import styles from "./deleteModal.module.css";
import type { Cluster } from "~/types";
import { useNavigate } from "react-router";

interface DeleteModalProps {
  cluster: Cluster;
}

export const DeleteModal = ({ cluster }: DeleteModalProps) => {
  const deleteCluster = useDeleteCluster();
  const navigate = useNavigate();

  const [inputString, setInputString] = useState("");

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const clusterName = cluster.name;
  const clusterId = cluster.id;

  const handleDelete = () => {
    deleteCluster.mutate(clusterId, {
      onError: () => {
        showFloatingMessage(
          <>
            An error occurred while attempting to delete the cluster{" "}
            <strong>{clusterName}</strong>. Please try again later.
          </>,
          {
            state: "warning",
            noIcon: true,
          },
        );
      },
      onSuccess: () => {
        showFloatingMessage(
          <>
            The cluster <strong>{clusterName}</strong> has been successfully
            deleted.
          </>,
          {
            state: "success",
            noIcon: true,
          },
        );
      },
      onSettled: () => {
        closeModal();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate("/clusters");
      },
    });
  };

  const closeModal = () => {
    handleModal({});
  };

  return (
    <Grid className={styles.modal}>
      <span>
        Are you sure you want to delete{" "}
        <span className={styles.clusterName}>{clusterName}</span>?
      </span>
      <Input
        className={styles.confirmationInput}
        label="Write cluster name to delete"
        placeholder="Cluster name"
        value={inputString}
        onChange={(e) => setInputString(e.target.value)}
      />
      <Inline>
        <Inline.Separator />
        <Button
          state="neutral"
          className={styles.modalButton}
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>

        <Button
          className={styles.modalButton}
          variant="filled"
          state="alert"
          disabled={inputString !== clusterName || deleteCluster.isPending}
          onClick={() => {
            handleDelete();
          }}
        >
          {deleteCluster.isPending ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <span>Deleting</span>
              <Icon.Spinner size={14} />
            </div>
          ) : (
            "Delete"
          )}
        </Button>
      </Inline>
    </Grid>
  );
};
