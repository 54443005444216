import {
  Button,
  Grid,
  Inline,
  useFloatingMessage,
} from "@intility/bifrost-react";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import { useRemoveMember } from "../api/removeMember";
import styles from "./RemoveMemberModal.module.css";
import { MemberType } from "~/constants";

interface RemoveMemberModal {
  team: {
    name: string;
    id: string;
    member: {
      id: string;
      name: string;
      memberType: MemberType;
    };
  };
}

export const RemoveMemberModal = ({ team }: RemoveMemberModal) => {
  const member = team.member;

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const removeMember = useRemoveMember();

  const handleRemoveMember = () => {
    removeMember.mutate(
      {
        teamId: team.id,
        memberId: member.id,
        memberType: member.memberType,
      },
      {
        onSuccess: () => {
          showFloatingMessage(
            <>
              <strong>{member.name}</strong> has been successfully removed from
              the team <strong>{team.name}</strong>.
            </>,
            { noIcon: true, state: "success" },
          );
        },
        onError: () => {
          showFloatingMessage(
            <>
              An error occurred while trying to remove{" "}
              <strong>{member.name}</strong> from the team{" "}
              <strong>{team.name}</strong>. Please try again.
            </>,
            { noIcon: true, state: "alert" },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  };

  return (
    <Grid gap={24} className={styles.modal}>
      <p>
        Are you sure you want to remove{" "}
        <span className={styles.memberName}>{member.name}</span> from the team?
      </p>

      <Inline>
        <Inline.Separator />
        <Button state="neutral" onClick={() => handleModal({})}>
          Cancel
        </Button>

        <Button
          state="alert"
          variant="filled"
          onClick={() => handleRemoveMember()}
        >
          Remove member
        </Button>
      </Inline>
    </Grid>
  );
};
