import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { Cluster, NodePool } from "~/types";

type CreateClusterDTO = {
  name: string;
  nodePools: NodePool[];
};

const createCluster = async (createDto: CreateClusterDTO) => {
  return api
    .post("api/v1/clusters", {
      json: createDto,
      prefixUrl: API.IDP.url,
    })
    .json<Cluster>();
};

export const useCreateCluster = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createDto: CreateClusterDTO) => createCluster(createDto),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.Clusters] });
    },
  });
};
