import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Input } from "@intility/bifrost-react";
import { type PropsWithChildren, useContext, useState } from "react";
import { ModalContext } from "~/context/ModalContext";
import { useUserTeams } from "../../api/getUserTeams";
import type { UserDTO } from "../../api/getUsers";
import { AddUserToTeamsModal } from "./AddUserToTeamsModal";
import styles from "./UserTeamsSection.module.css";
import { UserTeamsTable } from "./UserTeamsTable";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";

interface UserTeamsSectionProps {
  user: UserDTO;
}

export const UserTeamsSection = ({ user }: UserTeamsSectionProps) => {
  const userTeams = useUserTeams(user.id);

  const [teamFilter, setTeamFilter] = useState("");

  if (userTeams.isError) {
    return (
      <Layout user={user} showButton={userTeams.isSuccess}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout user={user} showButton={userTeams.isSuccess}>
      <div className={styles.searchInputWrapper}>
        <Input
          className={styles.searchInput}
          label="Search"
          hideLabel
          placeholder="Look up team memberships"
          rightIcon
          icon={faSearch}
          value={teamFilter}
          onChange={(e) => setTeamFilter(e.target.value)}
        />
      </div>

      <UserTeamsTable user={user} teamFilter={teamFilter} />
    </Layout>
  );
};

const Layout = ({
  children,
  user,
  showButton = true,
}: PropsWithChildren<{ user: UserDTO; showButton?: boolean }>) => {
  const { handleModal } = useContext(ModalContext);

  return (
    <div className={styles.container}>
      <div className={styles.infoHeader}>
        <div>
          <h5 className="bf-h5">Team memberships</h5>
          <p className={styles.tabDescription}>
            List of teams this user is a member of, and their role within the
            team.
          </p>
        </div>

        {showButton && (
          <Button
            className={styles.addUserButton}
            variant="filled"
            state="neutral"
            onClick={() =>
              handleModal({
                header: <>Add user to team</>,
                content: <AddUserToTeamsModal user={user} />,
              })
            }
          >
            <Icon marginRight icon={faPlus} />
            Add to team
          </Button>
        )}
      </div>

      {children}
    </div>
  );
};
