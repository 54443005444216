import "~/styles/reset.css";
// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import "@intility/bifrost-react/bifrost-app.css";
import "~/styles/global.css";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router/dom";
import { router } from "./router";
import { initializeSentry } from "./utils/initializeSentry";
import { initializeInstrumentations } from "./utils/registerInstrumentations";

const rootElement = document.getElementById("root")!;

// https://bifrost.intility.com/#/Components/Interactive/Modal
// Uncomment next line if using Modal from Bifrost
// Modal.setAppElement(rootElement);

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);

initializeSentry();
initializeInstrumentations();
