import {
  Button,
  Grid,
  Inline,
  useFloatingMessage,
} from "@intility/bifrost-react";
import type { TeamDTO } from "../../api/getTeam";
import { useDeleteTeam } from "../../api/deleteTeam";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import styles from "./DeleteTeamModal.module.css";

interface DeleteTeamModalProps {
  team: TeamDTO;
}

export const DeleteTeamModal = ({ team }: DeleteTeamModalProps) => {
  const deleteTeam = useDeleteTeam();

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const handleDeleteTeam = () => {
    if (deleteTeam.isPending) {
      return;
    }

    deleteTeam.mutate(team.id, {
      onSuccess: () => {
        showFloatingMessage(
          <>
            The team <strong>{team.name}</strong> has successfully been deleted.
          </>,
          {
            noIcon: true,
            state: "success",
          },
        );
      },
      onError: () => {
        showFloatingMessage(
          <>
            An error occurred while attempting to delete the team{" "}
            <strong>{team.name}</strong>. Please try again later.
          </>,
          {
            noIcon: true,
            state: "alert",
          },
        );
      },
      onSettled: () => {
        handleModal({});
      },
    });
  };

  return (
    <Grid gap={24} className={styles.modal}>
      <p>
        Are you sure you want to delete the team{" "}
        <span className={styles.teamName}>{team.name}</span>?
      </p>

      <Inline>
        <Inline.Separator />

        <Button state="neutral" onClick={() => handleModal({})}>
          Cancel
        </Button>
        <Button
          state={deleteTeam.isPending ? "inactive" : "alert"}
          variant="filled"
          onClick={() => handleDeleteTeam()}
        >
          Delete team
        </Button>
      </Inline>
    </Grid>
  );
};
