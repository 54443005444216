import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, type Role } from "~/constants";

type EditOrganizationRoleDTO = {
  roles: Role[];
};

const editOrganizationRole = (userId: string, dto: EditOrganizationRoleDTO) => {
  return api.put(`api/v1/users/${userId}/org-roles`, {
    json: dto,
    prefixUrl: API.IDP.url,
  });
};

export const useEditOrganizationRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      dto,
    }: { userId: string; dto: EditOrganizationRoleDTO }) =>
      editOrganizationRole(userId, dto),
    onSuccess: (response, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.Users] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.User, variables.userId],
      });
    },
  });
};
