import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { NodeSpecPreset } from "~/types";

type EditClusterSpecDTO = {
  nodePools: Array<{
    replicas: number;
    preset: NodeSpecPreset;
  }>;
};

const editClusterSpec = (clusterId: string, dto: EditClusterSpecDTO) => {
  return api.put(`api/v1/clusters/${clusterId}/nodepools`, {
    json: dto,
    prefixUrl: API.IDP.url,
  });
};

export const useEditClusterSpec = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      clusterId,
      dto,
    }: { clusterId: string; dto: EditClusterSpecDTO }) =>
      editClusterSpec(clusterId, dto),
    onSuccess: (response, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Cluster, variables.clusterId],
      });
    },
  });
};
