import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { UserDTO } from "./getUsers";

const getUser = (userId: string) => {
  return api
    .get(`api/v1/users/${userId}`, { prefixUrl: API.IDP.url })
    .json<UserDTO>();
};

export const userQuery = (userId: string) => ({
  queryKey: [QueryKey.User, userId],
  queryFn: () => getUser(userId),
  enabled: !!userId,
});

export const useUser = (userId: string) => {
  return useQuery(userQuery(userId));
};
