import { Tabs } from "@intility/bifrost-react";
import styles from "./accessManagementTabs.module.css";
import { NavLink } from "react-router";
import type { PropsWithChildren } from "react";

export const AccessManagementTabs = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <Tabs radiusTop>
        <NavLink to="/teams" end>
          Teams
        </NavLink>
        <NavLink to="/users" end>
          Users
        </NavLink>
      </Tabs>

      <div className={styles.tabContent}>{children}</div>
    </div>
  );
};
