import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { MemberType, QueryKey, Role } from "~/constants";

type TeamAssignableDTO = {
  id: string;
  name: string;
  details: string;
  type: MemberType;
  availableRoles: Role[];
  assignedRoles: Role[];
};

type TeamAssignablesDTO = TeamAssignableDTO[];

const getTeamAssignables = async (teamId: string) => {
  return api
    .get(`api/v1/teams/${teamId}/assignables`, { prefixUrl: API.IDP.url })
    .json<TeamAssignablesDTO>();
};

export const useTeamAssignables = (teamId: string) => {
  return useQuery({
    queryKey: [QueryKey.TeamAssignables, teamId],
    queryFn: () => getTeamAssignables(teamId),
  });
};
