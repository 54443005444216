import { AutoCol, Pagination, Skeleton } from "@intility/bifrost-react";
import { useState } from "react";
import { Link } from "react-router";
import { useSearch } from "~/hooks/useSearch";
import { cn } from "~/utils/clsx";
import { type UserDTO, useUsers } from "../../api/getUsers";
import { UserAvatar } from "../Avatar";
import { UserTypeBadge } from "../UserTypeBadge";
import styles from "./UsersGrid.module.css";

export const UsersGrid = () => {
  const users = useUsers();

  const [search] = useSearch();
  const [page, setPage] = useState(1);

  if (users.isPending) {
    return (
      <Layout>
        <GridItemSkeleton />
      </Layout>
    );
  }

  const filterUsers = () => {
    if (!search) {
      return users.data;
    }

    return users.data?.filter((user) =>
      user.name.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const filteredUsers = filterUsers() ?? [];
  const pageSize = 28;
  const pageCount = Math.ceil(filteredUsers.length / pageSize);

  const pagedUsers = filteredUsers.slice(
    (page - 1) * pageSize,
    page * pageSize,
  );

  return (
    <div>
      <Layout>
        {pagedUsers.map((user) => (
          <UserGridItem key={user.id} user={user} />
        ))}
      </Layout>

      {pageCount > 1 && (
        <Pagination
          currentPage={page}
          onChange={setPage}
          totalPages={pageCount}
          className={styles.pagination}
        />
      )}
    </div>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  return <AutoCol width={150}>{children}</AutoCol>;
};

const UserGridItem = ({ user }: { user: UserDTO }) => {
  return (
    <div className={styles.gridItemContainer}>
      <div>
        <UserAvatar size={80} />

        <div className={styles.badge}>
          <UserTypeBadge roles={user.roles} />
        </div>
      </div>

      <div className={styles.userInfo}>
        <Link to={`/users/${user.id}`}>
          <div className={cn("bf-link", styles.userName)}>{user.name}</div>
        </Link>
        <div className={styles.userUpn}>{user.upn}</div>
      </div>
    </div>
  );
};

const GridItemSkeleton = () => {
  return (
    <Skeleton repeat={10}>
      <div className={styles.gridItemContainer}>
        <Skeleton.Rect className={styles.image} height={80} width={80} />

        <Skeleton.Text className={styles.userName} width="80%" />

        <Skeleton.Text className={styles.userUpn} width="90%" />
        <Skeleton.Text
          className={styles.userUpn}
          width="60%"
          style={{ marginTop: 0 }}
        />
      </div>
    </Skeleton>
  );
};
