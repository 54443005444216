/* document-load.ts|js file - the code snippet is the same for both the languages */
import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from "@opentelemetry/core";
import {
  BatchSpanProcessor,
  WebTracerProvider,
} from "@opentelemetry/sdk-trace-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { ATTR_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
import { Resource } from "@opentelemetry/resources";
import { env } from "./env";

const provider = new WebTracerProvider({
  resource: new Resource({
    [ATTR_SERVICE_NAME]: env.otelServiceName,
  }),
});

provider.addSpanProcessor(
  new BatchSpanProcessor(
    new OTLPTraceExporter({
      url: env.otelCollectorUrl,
    }),
  ),
);

provider.register({
  // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
  contextManager: new ZoneContextManager(),
  propagator: new CompositePropagator({
    propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
  }),
});

function initializeInstrumentations() {
  if (import.meta.env.PROD && import.meta.env.MODE === "production") {
    // Registering instrumentations
    registerInstrumentations({
      instrumentations: [
        getWebAutoInstrumentations({
          // load custom configuration for xml-http-request instrumentation
          "@opentelemetry/instrumentation-xml-http-request": {
            clearTimingResources: true,
          },
          "@opentelemetry/instrumentation-fetch": {
            propagateTraceHeaderCorsUrls: [new RegExp(env.idpApiUrl)],
          },
        }),
      ],
    });
  } else {
    console.log("Not prod. Skipping instrumentation setup");
  }
}

export { initializeInstrumentations };
