import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Input, Label } from "@intility/bifrost-react";
import { useContext } from "react";
import { Role } from "~/constants";
import { ModalContext } from "~/context/ModalContext";
import { useSearch } from "~/hooks/useSearch";
import { useRole } from "../../hooks/useRole";
import { CreateTeamModal } from "./CreateTeamModal";
import styles from "./TeamsTableOptions.module.css";

const roleButtons: Array<{ label: string; value: Role | null }> = [
  { label: "All", value: null },
  { label: "Member", value: Role.Member },
  { label: "Owner", value: Role.Owner },
] as const;

export const TeamsTableOptions = () => {
  const { handleModal } = useContext(ModalContext);

  const [search, setSearch] = useSearch();
  const [role, setRole] = useRole();

  return (
    <div className={styles.container}>
      <Input
        label="Search"
        placeholder="Look up team"
        defaultValue={search}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={(e) => setSearch(e.target.value)}
        className={styles.input}
        rightIcon
        icon={faSearch}
      />

      <div className={styles.rolesWrapper}>
        <Label style={{ marginBottom: "4px" }}>Your role</Label>
        <Button.Group>
          {roleButtons.map((roleButton) => (
            <Button
              key={roleButton.label}
              active={role === roleButton.value}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => setRole(roleButton.value)}
            >
              {roleButton.label}
            </Button>
          ))}
        </Button.Group>
      </div>

      <Button
        variant="filled"
        state="neutral"
        className={styles.createTeamButton}
        onClick={() =>
          handleModal({
            content: <CreateTeamModal />,
            header: <>Create team</>,
          })
        }
      >
        <Icon icon={faPlus} marginRight />
        Create team
      </Button>
    </div>
  );
};
