import { createColumnHelper } from "@tanstack/react-table";
import type { ClusterMemberDTO } from "../../api/getClusterMembers";
import styles from "./ClusterMembersTable.module.css";
import { Link } from "react-router";
import { ModalContext } from "~/context/ModalContext";
import { useContext } from "react";
import { Button, Dropdown, Icon } from "@intility/bifrost-react";
import { faEllipsisH, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { ClusterRoleBadge } from "~/components/ClusterRoleBadge";
import { RemoveClusterAccessModal } from "./RemoveClusterAccessModal";
import { ClusterRole } from "~/constants";
import { getClusterRoleFromRoles } from "~/utils/getRoleFromRoles";
import { sortByClusterRole } from "~/utils/sortByRole";
import { cn } from "~/utils/clsx";
import { useRole } from "~/hooks/useRole";

const columnHelper = createColumnHelper<ClusterMemberDTO>();

export const clusterMemberColumns = [
  columnHelper.accessor("subject.name", {
    header: "Name",
    id: "name",
    meta: { cellClassName: styles.nameCell },
    cell: function Cell(cell) {
      const member = cell.row.original;

      const isUser = member.subject.type === "user";
      const subjectPath = isUser ? "users" : "teams";
      const memberId = member.subject.id;

      return (
        <div>
          <Link to={`/${subjectPath}/${memberId}`} className={"bf-title-link"}>
            {member.subject.name}
          </Link>

          <div className={cn("from-small", styles.memberDetails)}>
            {member.subject.details}
          </div>
        </div>
      );
    },
  }),
  columnHelper.accessor("subject.type", {
    header: "Type",
    meta: {
      fromSize: "small",
    },
    cell: function Cell(cell) {
      const member = cell.row.original;

      if (member.subject.type === "user") {
        return "User";
      }

      return "Team";
    },
  }),
  columnHelper.accessor(
    (clusterMember) => getClusterRoleFromRoles(clusterMember.roles),
    {
      header: "Role",
      id: "roles",
      sortingFn: (rowA, rowB) => {
        const roleA = getClusterRoleFromRoles(rowA.original.roles);
        const roleB = getClusterRoleFromRoles(rowB.original.roles);

        return sortByClusterRole(roleA, roleB);
      },
      cell: function Cell(cell) {
        const member = cell.row.original;

        return <ClusterRoleBadge roles={member.roles} />;
      },
    },
  ),
  columnHelper.display({
    id: "actions",
    meta: { cellClassName: styles.actionsCell },
    cell: function Cell(cell) {
      const { handleModal } = useContext(ModalContext);

      const meta = cell.table.options.meta;
      const cluster = meta?.cluster;
      const isOrgAdmin = useRole().isAdmin;

      if (!cluster) {
        return null;
      }

      const member = cell.row.original;
      const isClusterAdmin = cluster.roles.includes(ClusterRole.Admin);

      if (!isClusterAdmin && !isOrgAdmin) {
        return null;
      }

      return (
        <Dropdown
          noPadding
          content={
            <div className={styles.actionsDropdown}>
              <Button
                type="button"
                variant="flat"
                state="neutral"
                onClick={(e) => {
                  e.stopPropagation();

                  handleModal({
                    header: <>Revoke cluster access</>,
                    content: (
                      <RemoveClusterAccessModal
                        cluster={{
                          id: cluster.id,
                          name: cluster.name,
                          member: {
                            id: member.subject.id,
                            name: member.subject.name,
                            memberType: member.subject.type,
                          },
                        }}
                      />
                    ),
                  });
                }}
              >
                <Icon icon={faTrashCan} marginRight />
                Revoke cluster access
              </Button>
            </div>
          }
        >
          <Button
            pill
            noPadding
            variant="flat"
            state="neutral"
            small
            aria-label={`Open actions for ${member.subject.name}`}
            onClick={(e) => e.stopPropagation()}
          >
            <Icon icon={faEllipsisH} />
          </Button>
        </Dropdown>
      );
    },
  }),
];
