import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { env } from "./env";

/**
 * Initializes sentry if the application is built for production.
 * @see https://docs.sentry.io/platforms/javascript/guides/react
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
function initializeSentry() {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: env.sentryDsn,
      release: env.sentryRelease,
      environment: env.sentryEnv,
      integrations: [
        Sentry.reactRouterV7BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  } else {
    console.log("Not prod. Skipping Sentry");
  }
}

export { initializeSentry };
