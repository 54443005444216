import cpmLogo from "~/assets/cpm-logo.svg";
import styles from "./SignInPage.module.css";

import { Link } from "react-router";
import { SignInBackground } from "./components/SignInBackground";
import { SignInButton } from "./components/SignInButton";
import { SignInFooter } from "./components/SignInFooter";

export const SignInPage = () => {
  return (
    <div className={styles.pageContainer}>
      <SignInBackground />

      <div className={styles.signInWrapper}>
        <Link to="/">
          <img src={cpmLogo} alt="" className={styles.cpmLogo} />
        </Link>

        <div className={styles.textSection}>
          <p className={styles.welcomeText}>Welcome to</p>
          <h5 className="bf-h5">Developer Platform</h5>
          <p className={styles.slogan}>
            Spend more time on development and less time on everything else
          </p>
        </div>

        <SignInButton />
        <SignInFooter />
      </div>
    </div>
  );
};
