import {
  Button,
  Grid,
  Input,
  TextArea,
  useFloatingMessage,
} from "@intility/bifrost-react";
import styles from "./EditTeamModal.module.css";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { TeamDTO } from "../../api/getTeam";
import { useUpdateTeam } from "../../api/updateTeam";
import { createTeamSchema } from "../access/CreateTeamModal";

interface EditTeamModalProps {
  team: TeamDTO;
}

const editTeamSchema = createTeamSchema;

export const EditTeamModal = ({ team }: EditTeamModalProps) => {
  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const updateTeam = useUpdateTeam();

  const { control, handleSubmit, formState } = useForm({
    resolver: zodResolver(editTeamSchema),
    defaultValues: {
      name: team.name,
      description: team.description,
    },
  });

  const onSubmit = handleSubmit((data) => {
    const updateDto = {
      name: data.name,
      description: data.description,
    };

    updateTeam.mutate(
      { teamId: team.id, updateDto },
      {
        onSuccess: () => {
          showFloatingMessage(
            <>
              The team <strong>{team.name}</strong> has been successfully
              updated.
            </>,
            {
              state: "success",
              noIcon: true,
            },
          );
        },
        onError: () => {
          showFloatingMessage(
            <>
              An error occurred while attempting to update the team{" "}
              <strong>{team.name}</strong>. Please try again.
            </>,
            {
              state: "alert",
              noIcon: true,
            },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  });

  return (
    <form className={styles.container} onSubmit={(e) => void onSubmit(e)}>
      <Grid>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label="Team name"
              feedback={fieldState.error?.message ?? ""}
              required
              state={
                fieldState.isTouched && fieldState.error ? "alert" : undefined
              }
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Description"
              className={styles.descriptionInput}
              feedback={fieldState.error?.message ?? ""}
              required
              state={
                fieldState.isTouched && fieldState.error ? "alert" : undefined
              }
            />
          )}
        />

        <div className={styles.buttonContainer}>
          <Button
            variant="flat"
            state="neutral"
            onClick={() => handleModal({})}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="filled"
            state={!formState.isValid ? "inactive" : "neutral"}
          >
            Confirm
          </Button>
        </div>
      </Grid>
    </form>
  );
};
