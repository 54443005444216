import styles from "./callToAction.module.css";
import pekeDame from "~/assets/peke-dame.svg";

const CallToAction = () => {
  return (
    <div className={styles.illustration}>
      <span className={styles.actionText}>
        Click the button to <br /> create your first cluster
      </span>
      <img
        width={177}
        src={pekeDame}
        alt="Illustration of a woman pointing at create cluster button"
      />
    </div>
  );
};

export default CallToAction;
