import { faEllipsisH, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { Button, Dropdown, Icon } from "@intility/bifrost-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext } from "react";
import { Link } from "react-router";
import { ModalContext } from "~/context/ModalContext";
import { useTeam, type TeamDTO } from "../../api/getTeam";
import { RemoveMemberModal } from "../RemoveMemberModal";
import { RoleBadge } from "../../../../components/RoleBadge";
import styles from "./UserTeamsTable.module.css";
import { useRole } from "~/hooks/useRole";
import { Role } from "~/constants";
import { getRoleFromRoles } from "~/utils/getRoleFromRoles";
import { sortByRole } from "~/utils/sortByRole";

const columnHelper = createColumnHelper<TeamDTO>();

export const userTeamColumns = [
  columnHelper.accessor("name", {
    id: "name",
    meta: { cellClassName: styles.teamNameCell },
    cell: function Cell(cell) {
      const team = cell.row.original;

      return (
        <div className={styles.teamProfile}>
          <Link to={`/teams/${team.id}`} className="bf-title-link">
            {team.name}
          </Link>

          <div className={styles.teamDescription}>{team.description}</div>
        </div>
      );
    },
  }),
  columnHelper.accessor((team) => getRoleFromRoles(team.roles), {
    id: "role",
    meta: { cellClassName: styles.roleCell },
    sortingFn: (rowA, rowB) => {
      const roleA = getRoleFromRoles(rowA.original.roles);
      const roleB = getRoleFromRoles(rowB.original.roles);

      return sortByRole(roleA, roleB);
    },
    cell: function Cell(cell) {
      const team = cell.row.original;

      return <RoleBadge roles={team.roles} />;
    },
  }),
  columnHelper.display({
    id: "actions",
    meta: { cellClassName: styles.actionsCell },
    cell: function Cell(cell) {
      const { handleModal } = useContext(ModalContext);

      const userTeam = cell.row.original;
      const member = cell.table.options.meta?.member;

      const team = useTeam(userTeam.id);
      const role = useRole();
      const isOrgAdmin = role.isAdmin;
      const isTeamOwner = team.data?.roles.includes(Role.Owner);

      if (!isOrgAdmin && !isTeamOwner) {
        return null;
      }

      if (!member) {
        return null;
      }

      return (
        <Dropdown
          noPadding
          content={
            <div className={styles.actionsDropdown}>
              <Button
                type="button"
                variant="flat"
                state="neutral"
                onClick={() =>
                  handleModal({
                    header: <>Remove user from team</>,
                    content: (
                      <RemoveMemberModal
                        team={{
                          id: userTeam.id,
                          name: userTeam.name,
                          member: {
                            id: member.id,
                            name: member.name,
                            memberType: member.memberType,
                          },
                        }}
                      />
                    ),
                  })
                }
              >
                <Icon icon={faTrashCan} marginRight />
                Revoke team access
              </Button>
            </div>
          }
        >
          <Button
            pill
            noPadding
            variant="flat"
            state="neutral"
            small
            aria-label={`Open actions for ${userTeam.name}`}
          >
            <Icon icon={faEllipsisH} />
          </Button>
        </Dropdown>
      );
    },
  }),
];
