import { useParams } from "react-router";
import { ClusterBreadcrumbs } from "../components/clusterDetails/ClusterBreadcrumbs";
import { useCluster } from "../api/getCluster";
import styles from "./EditCluster.module.css";
import { Icon } from "@intility/bifrost-react";
import { type Cluster as TCluster } from "~/types";
import { type PropsWithChildren } from "react";
import { HTTPError } from "ky";
import { ErrorPage } from "~/pages/error/ErrorPage";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { EditClusterForm } from "../components/clusterDetails/EditClusterForm";

export const EditCluster = () => {
  const { clusterId } = useParams();

  const cluster = useCluster(clusterId ?? "");

  if (cluster.isPending) {
    return (
      <Layout cluster={cluster.data}>
        <Icon.Spinner size={42} className={styles.loadingSpinner} />
      </Layout>
    );
  }

  if (
    cluster.error instanceof HTTPError &&
    cluster.error.response.status === 403
  ) {
    return <ErrorPage code={403} />;
  }

  if (cluster.isError) {
    return (
      <Layout cluster={cluster.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster.data}>
      <EditClusterForm cluster={cluster.data} />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
}: PropsWithChildren<{ cluster: TCluster | undefined }>) => {
  return (
    <div className={styles.content}>
      <ClusterBreadcrumbs cluster={cluster} />

      {children}
    </div>
  );
};
