import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { Cluster } from "~/types";

const getClusters = async () => {
  const data = await api
    .get(`api/v1/clusters`, { prefixUrl: API.IDP.url })
    .json<Cluster[]>();

  return data;
};

type UseClustersParameters = {
  refetchInterval?: number;
};

export const clustersQuery = (parameters?: UseClustersParameters) => ({
  queryKey: [QueryKey.Clusters],
  queryFn: async () => getClusters(),
  refetchInterval: parameters?.refetchInterval,
});

export const useClusters = (parameters?: UseClustersParameters) => {
  return useQuery(clustersQuery(parameters));
};
