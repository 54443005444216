import { Badge } from "@intility/bifrost-react";
import {
  ArticleCategory,
  type ArticleCategoryValue,
} from "../../hooks/useArticleCategories";
import styles from "./articleCard.module.css";

const CategoryLabel = {
  [ArticleCategory.LEARN_MORE.value]: "Learn more",
  [ArticleCategory.GUIDE.value]: "Guide",
} satisfies Record<ArticleCategoryValue, ArticleCategory["label"]>;

const ArticleCard = ({
  imgSrc,
  imgAlt,
  title,
  ingress,
  href,
  category,
}: {
  imgSrc: string;
  imgAlt: string;
  title: string;
  ingress: string;
  href: string;
  category: ArticleCategoryValue;
}) => {
  const categoryLabel = CategoryLabel[category];

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={styles.card}>
        <img
          className={styles.cardImage}
          src={imgSrc}
          alt={imgAlt}
          width={197}
          height={166}
        />
        <div className={styles.textSection}>
          <h5 className="bf-h5">{title}</h5>
          <span className={styles.ingress}>{ingress}</span>

          <Badge state="neutral" pill className={styles.categoryBadge}>
            {categoryLabel}
          </Badge>
        </div>
      </div>
    </a>
  );
};

export default ArticleCard;
