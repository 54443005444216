import { Breadcrumbs } from "@intility/bifrost-react";
import styles from "./breadcrumbsHeader.module.css";
import { Link } from "react-router";

const BreadcrumbsHeader = () => {
  return (
    <div className={styles.content}>
      <Breadcrumbs>
        <Breadcrumbs.Item>
          <Link to="/clusters">Clusters</Link>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>New cluster</Breadcrumbs.Item>
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsHeader;
