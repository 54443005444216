import { type RefObject, useEffect } from "react";

interface RefTarget {
  contains(target: EventTarget | null): unknown;
}

function useClickOutside(
  ref: RefObject<RefTarget>,
  onClickOutside: (event: MouseEvent) => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!ref.current) {
        return;
      }

      const containsElement = ref.current.contains(event.target);

      if (!containsElement) {
        onClickOutside(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export { useClickOutside };
