import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { MemberType, QueryKey } from "~/constants";

const removeTeamMember = ({
  teamId,
  memberId,
  memberType,
}: RemoveMemberParams) => {
  const memberIdPrefix = `${memberType}:`;

  return api
    .delete(`api/v1/teams/${teamId}/members/${memberIdPrefix}${memberId}`, {
      prefixUrl: API.IDP.url,
    })
    .json();
};

type RemoveMemberParams = {
  teamId: string;
  memberId: string;
  memberType: MemberType;
};

export const useRemoveMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, memberId, memberType }: RemoveMemberParams) =>
      removeTeamMember({ teamId, memberId, memberType }),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.TeamMembers] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.UserTeams],
      });
    },
  });
};
