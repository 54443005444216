import { API } from "~/config/api";
import { api } from "~/auth/fetch";
import { QueryKey } from "~/constants";
import { useQuery } from "@tanstack/react-query";
import type { TeamDTO } from "./getTeam";

const getUserTeams = (userId: string) => {
  return api
    .get(`api/v1/users/${userId}/teams`, { prefixUrl: API.IDP.url })
    .json<TeamDTO[]>();
};

export const useUserTeams = (userId: string) => {
  return useQuery({
    queryKey: [QueryKey.UserTeams, userId],
    queryFn: () => getUserTeams(userId),
  });
};
