import {
  faCircleDown,
  faCircleUp,
  faSpinner,
  type IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

export const ClusterStatus = {
  ready: "ready",
  notReady: "notReady",
  deploying: "deploying",
} as const;

export type ClusterStatus = (typeof ClusterStatus)[keyof typeof ClusterStatus];

export const ClusterStatusIcon = {
  ready: { icon: faCircleUp, color: "var(--bfc-base-c-success)" },
  notReady: { icon: faCircleDown, color: "var(--bfc-base-c-alert)" },
  deploying: { icon: faSpinner, color: "var(--bfc-base-c-2)" },
} as const satisfies Record<
  ClusterStatus,
  { icon: IconDefinition; color: string }
>;

export const QueryKey = {
  Clusters: "clusters",
  ProfilePicture: "profilePicture",
  Cluster: "cluster",
  Users: "users",
  Teams: "teams",
  UserTeams: "userTeams",
  TeamMembers: "teamMembers",
  User: "user",
  Team: "team",
  UserClusters: "userClusters",
  TeamAssignables: "teamAssignables",
  TeamClusters: "teamClusters",
  Me: "me",
  ClusterAssignables: "clusterAssignables",
  UserAssignableClusters: "userAssignableClusters",
  TeamAssignableClusters: "teamAssignableClusters",
  ClusterMembers: "clusterMembers",
} as const;

export const Role = {
  Member: "member",
  Owner: "owner",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

export const MemberType = {
  User: "user",
  Team: "team",
} as const;

export type MemberType = (typeof MemberType)[keyof typeof MemberType];

export const ClusterRole = {
  Admin: "admin",
  Reader: "reader",
} as const;

export type ClusterRole = (typeof ClusterRole)[keyof typeof ClusterRole];
