import { faEllipsisH, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { Button, Dropdown, Icon } from "@intility/bifrost-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext } from "react";
import { Link } from "react-router";
import { ModalContext } from "~/context/ModalContext";
import type { TeamMemberDTO } from "../../api/getTeamMembers";
import { RemoveMemberModal } from "../RemoveMemberModal";
import { RoleBadge } from "../../../../components/RoleBadge";
import styles from "./TeamMembersTable.module.css";
import { getRoleFromRoles } from "~/utils/getRoleFromRoles";
import { sortByRole } from "~/utils/sortByRole";

const columnHelper = createColumnHelper<TeamMemberDTO>();

export const teamMemberColumns = [
  columnHelper.accessor("subject.name", {
    id: "name",
    meta: {
      cellClassName: styles.memberNameCell,
    },
    cell: function Cell(cell) {
      const member = cell.row.original;

      const isUser = member.subject.type === "user";
      const subjectPath = isUser ? "users" : "teams";
      const memberId = member.subject.id;

      return (
        <div className={styles.memberProfile}>
          <div>
            <Link
              to={`/${subjectPath}/${memberId}`}
              className={"bf-title-link"}
            >
              {member.subject.name}
            </Link>

            <div className={styles.memberDetails}>{member.subject.details}</div>
          </div>
        </div>
      );
    },
  }),
  columnHelper.accessor((team) => getRoleFromRoles(team.roles), {
    id: "role",
    meta: { cellClassName: styles.roleCell },
    sortingFn: (rowA, rowB) => {
      const roleA = getRoleFromRoles(rowA.original.roles);
      const roleB = getRoleFromRoles(rowB.original.roles);

      return sortByRole(roleA, roleB);
    },
    cell: function Cell(cell) {
      const member = cell.row.original;

      return <RoleBadge roles={member.roles} />;
    },
  }),
  columnHelper.display({
    id: "actions",
    meta: { cellClassName: styles.actionsCell },
    cell: function Cell(cell) {
      const { handleModal } = useContext(ModalContext);

      const member = cell.row.original;
      const team = cell.table.options.meta?.team;
      const canEdit = cell.table.options.meta?.canEdit;

      if (!team || !canEdit) {
        return null;
      }

      return (
        <Dropdown
          noPadding
          content={
            <div className={styles.actionsDropdown}>
              <Button
                type="button"
                variant="flat"
                state="neutral"
                onClick={() =>
                  handleModal({
                    header: <>Remove user from team</>,
                    content: (
                      <RemoveMemberModal
                        team={{
                          id: team.id,
                          name: team.name,
                          member: {
                            id: member.subject.id,
                            name: member.subject.name,
                            memberType: member.subject.type,
                          },
                        }}
                      />
                    ),
                  })
                }
              >
                <Icon icon={faTrashCan} marginRight />
                Revoke team access
              </Button>
            </div>
          }
        >
          <Button
            pill
            noPadding
            variant="flat"
            state="neutral"
            small
            aria-label={`Open actions for ${member.subject.name}`}
          >
            <Icon icon={faEllipsisH} />
          </Button>
        </Dropdown>
      );
    },
  }),
];
