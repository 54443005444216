import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Input } from "@intility/bifrost-react";
import { type PropsWithChildren, useState } from "react";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import type { Cluster } from "~/types";
import { useClusterMembers } from "../../api/getClusterMembers";
import { AddClusterAccessModal } from "./AddClusterAccessModal";
import styles from "./ClusterMembersSection.module.css";
import { ClusterMembersTable } from "./ClusterMembersTable";
import { ClusterRole } from "~/constants";
import { useRole } from "~/hooks/useRole";

interface ClusterMembersSectionProps {
  cluster: Cluster;
}

export const ClusterMembersSection = ({
  cluster,
}: ClusterMembersSectionProps) => {
  const clusterMembers = useClusterMembers(cluster.id);
  const role = useRole();

  const [memberFilter, setMemberFilter] = useState("");

  const isClusterAdmin = cluster.roles.includes(ClusterRole.Admin);
  const isOrgAdmin = role.isAdmin;
  const showGiveAccessButton = isClusterAdmin || isOrgAdmin;

  if (clusterMembers.isError) {
    return (
      <Layout cluster={cluster} showButton={showGiveAccessButton}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster} showButton={showGiveAccessButton}>
      <div className={styles.searchInputWrapper}>
        <Input
          className={styles.memberSearchInput}
          label="Search"
          hideLabel
          placeholder="Look up member"
          rightIcon
          icon={faSearch}
          value={memberFilter}
          onChange={(e) => setMemberFilter(e.target.value)}
        />
      </div>

      <ClusterMembersTable cluster={cluster} filterValue={memberFilter} />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
  showButton = true,
}: PropsWithChildren<{ cluster: Cluster; showButton: boolean }>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.infoHeader}>
        <div>
          <h5 className="bf-h5">Members with cluster access</h5>
          <span className={styles.pageDescription}>
            List of teams and users with access to this cluster, and their
            access role.
          </span>
        </div>

        {showButton && (
          <Button
            className={styles.giveAccessButton}
            state="neutral"
            variant="filled"
            onClick={() => setIsOpen(true)}
          >
            <Icon icon={faPlus} marginRight />
            Give access
          </Button>
        )}
      </div>

      {children}

      <AddClusterAccessModal
        cluster={cluster}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};
