import { Box } from "@intility/bifrost-react";
import { ColorSchemedImage } from "~/components/ColorSchemedImage";
import tropicalChillLight from "~/assets/tropical-chill-light.svg";
import tropicalChillDark from "~/assets/tropical-chill-dark.svg";
import styles from "./NoResults.module.css";

interface NoResultsProps {
  message: string;
}

export const NoResults = ({ message }: NoResultsProps) => {
  return (
    <Box background="base-3" radius padding className={styles.container}>
      <ColorSchemedImage
        srcLight={tropicalChillLight}
        srcDark={tropicalChillDark}
        alt=""
      />

      <span className={styles.message}>{message}</span>
    </Box>
  );
};
