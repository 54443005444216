import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "~/constants";
import type { TeamDTO } from "./getTeam";

const getTeams = () => {
  return api.get("api/v1/teams", { prefixUrl: API.IDP.url }).json<TeamDTO[]>();
};

export const teamsQuery = () => ({
  queryKey: [QueryKey.Teams],
  queryFn: getTeams,
});

export const useTeams = () => {
  return useQuery(teamsQuery());
};
