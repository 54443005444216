import { Badge, Box } from "@intility/bifrost-react";
import styles from "./GuideCard.module.css";
import kubernetesRbacImg from "~/assets/images/articles/kubernetes-rbac.jpg";
import { cn } from "~/utils/clsx";

export const GuideCard = () => {
  const handleCardClick = () => {
    window.open(
      "https://article.intility.com/69963862-be3f-4588-8c3c-08dce9d0db05",
      "_blank",
      "noreferrer",
    );
  };

  return (
    <Box
      padding
      shadow
      radius
      background="base-3"
      className={styles.cardContainer}
      onClick={handleCardClick}
      onKeyUp={handleCardClick}
      role="button"
      tabIndex={0}
    >
      <div>
        <h6 className={styles.guideTitle}>
          Best practice for RBAC in Kubernetes clusters
        </h6>

        <div className={styles.paragraphContainer}>
          <p className={styles.guideParagraph}>
            While ownership of one&apos;s own Kubernetes cluster has a number of
            practical benefits, there are a few things to note when it comes to
            RBAC to keep the cluster secure and stable.
          </p>

          <p
            className={cn(
              "from-medium",
              styles.guideParagraph,
              styles.singleLineClamp,
            )}
          >
            This guide is heavily based on the &quot;
            <a
              className="bf-link"
              href="https://kubernetes.io/docs/concepts/security/rbac-good-practices"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              Role Based Access Control Good Practices
            </a>
            &quot; and &quot;
            <a
              className="bf-link"
              href="https://kubernetes.io/docs/reference/access-authn-authz/rbac"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              Using RBAC Authorization
            </a>
            &quot; articles in the upstream Kubernetes documentation, which are
            also highly encouraged to have a look at.
          </p>
        </div>

        <div className={styles.badgesContainer}>
          <Badge state="neutral" pill>
            Guide
          </Badge>
          <Badge state="neutral" pill>
            RBAC
          </Badge>
          <Badge state="neutral" pill>
            Roles
          </Badge>
        </div>
      </div>

      <img src={kubernetesRbacImg} alt="" className={styles.guideImage} />
    </Box>
  );
};
