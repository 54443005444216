import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";

type UpdateTeamDTO = {
  name: string;
  description: string;
};

const updateTeam = async (teamId: string, updateDto: UpdateTeamDTO) => {
  return api.put(`api/v1/teams/${teamId}`, {
    prefixUrl: API.IDP.url,
    json: updateDto,
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      teamId,
      updateDto,
    }: { teamId: string; updateDto: UpdateTeamDTO }) =>
      updateTeam(teamId, updateDto),
    onSuccess: (data, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Team, variables.teamId],
      });
    },
  });
};
