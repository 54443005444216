import type { Cluster } from "~/types";
import styles from "./ClusterSpecificationCards.module.css";
import { faCar, faCircleNodes } from "@fortawesome/pro-regular-svg-icons";
import { Grid, Box, Icon } from "@intility/bifrost-react";

export const ClusterSpecificationCards = ({
  cluster,
}: { cluster: Cluster }) => {
  const nodePool = cluster.nodePools.at(0);
  const cores = nodePool?.compute?.cores ?? 0;
  const nodeCount = nodePool?.replicas ?? 0;

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const memory = nodePool?.compute?.memory || "0 Gi";

  const memoryAmount = memory.split("Gi").at(0);
  const memoryDisplay = memoryAmount ? `${memoryAmount} GB` : undefined;

  return (
    <>
      <Grid.Span large={2}>
        <Box padding radius background="base-2" className={styles.computeCard}>
          <div className={styles.iconBackground}>
            <Icon icon={faCar} fixedWidth color="var(--bfc-base-c-attn)" />
          </div>

          <div>
            <div className={styles.computeTitle}>
              <span>{cores} CPU</span>
              <span className={styles.computeDivider} />
              <span>{memoryDisplay} RAM</span>
            </div>

            <p className={styles.computeDescription}>
              Processing power and memory
            </p>
          </div>
        </Box>
      </Grid.Span>

      <Box padding radius background="base-2" className={styles.nodeCountCard}>
        <div className={styles.iconBackground}>
          <Icon
            icon={faCircleNodes}
            fixedWidth
            color="var(--bfc-base-c-attn)"
          />
        </div>

        <div>
          <div className={styles.nodeCountTitle}>{nodeCount} nodes</div>
          <div className={styles.nodeCountDescription}>Node count</div>
        </div>
      </Box>
    </>
  );
};
