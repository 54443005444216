import { Icon } from "@intility/bifrost-react";
import { type PropsWithChildren } from "react";
import { useParams } from "react-router";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import type { Cluster } from "~/types";
import { useCluster } from "../api/getCluster";
import { ClusterBreadcrumbs } from "../components/clusterDetails/ClusterBreadcrumbs";
import { ClusterMembersSection } from "../components/clusterDetails/ClusterMembersSection";
import styles from "./ClusterAccess.module.css";
import { HTTPError } from "ky";
import { ErrorPage } from "~/pages/error/ErrorPage";

export const ClusterAccess = () => {
  const { clusterId } = useParams();

  const cluster = useCluster(clusterId ?? "");

  if (cluster.isPending) {
    return (
      <Layout cluster={cluster.data}>
        <Icon.Spinner className={styles.loadingSpinner} size={42} />
      </Layout>
    );
  }

  if (
    cluster.error instanceof HTTPError &&
    cluster.error.response.status === 403
  ) {
    return <ErrorPage code={403} />;
  }

  if (cluster.isError) {
    return (
      <Layout cluster={cluster.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster.data}>
      <ClusterMembersSection cluster={cluster.data} />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
}: PropsWithChildren<{ cluster: Cluster | undefined }>) => {
  return (
    <div className={styles.content}>
      <ClusterBreadcrumbs cluster={cluster} />

      {children}
    </div>
  );
};
