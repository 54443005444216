import { faPeopleGroup, faUserVneck } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@intility/bifrost-react";
import styles from "./Avatar.module.css";
import { cn } from "~/utils/clsx";
import type { PropsWithChildren } from "react";

interface AvatarProps {
  size: number;
  className?: string;
}

const Avatar = ({
  size,
  className,
  children,
  type,
}: PropsWithChildren<
  AvatarProps & {
    type: "user" | "team";
  }
>) => {
  return (
    <div
      className={cn(className, styles.iconBackground)}
      style={{ height: size, width: size }}
    >
      <Icon
        icon={type === "team" ? faPeopleGroup : faUserVneck}
        color="var(--bfc-base-c-attn)"
      />

      {children}
    </div>
  );
};

const TeamAvatar = ({ size, className }: AvatarProps) => {
  return <Avatar size={size} className={className} type="team" />;
};

const UserAvatar = ({ size, className }: AvatarProps) => {
  return <Avatar size={size} className={className} type="user" />;
};

export { TeamAvatar, UserAvatar };
