import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Grid,
  Inline,
  Input,
  Modal,
  useFloatingMessage,
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { MemberType, Role } from "~/constants";
import { useAddTeamMembers } from "../../api/addTeamMembers";
import type { TeamDTO } from "../../api/getTeam";
import { useTeamAssignables } from "../../api/getTeamAssignables";
import { TeamRoleDescriptionTable } from "../TeamRoleDescriptionTable";
import styles from "./AddTeamMembersModal.module.css";
import { useClickOutside } from "~/hooks/useClickOutside";

const roleOptions = [
  { label: "Member", value: Role.Member },
  { label: "Owner", value: Role.Owner },
];

const addTeamMembersSchema = z.object({
  teamId: z.string(),
  role: z.object(
    {
      label: z.string(),
      value: z.nativeEnum(Role),
    },
    { required_error: "Required" },
  ),
  members: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    )
    .min(1, "Required"),
});

type AddTeamMembersSchema = z.infer<typeof addTeamMembersSchema>;
type AssignableOption = { label: string; value: string };

interface AddTeamMembersModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  team: TeamDTO;
}

export const AddTeamMembersModal = ({
  isOpen,
  setIsOpen,
  team,
}: AddTeamMembersModalProps) => {
  const teamAssignables = useTeamAssignables(team.id);
  const addMembers = useAddTeamMembers();
  const selectRef = useRef<HTMLDivElement | null>(null);
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const [isMembersMenuOpen, setIsMembersMenuOpen] = useState(false);

  const { showFloatingMessage } = useFloatingMessage();

  const { control, handleSubmit, formState, reset } =
    useForm<AddTeamMembersSchema>({
      resolver: zodResolver(addTeamMembersSchema),
      defaultValues: { teamId: team.id },
    });

  const isValid = formState.isValid;

  const onSubmit = handleSubmit((data) => {
    const memberCount = data.members.length;

    addMembers.mutate(
      {
        teamId: team.id,
        dto: data.members.map((member) => ({
          subject: { id: member.value, type: "user" },
          roles: [data.role.value],
        })),
      },
      {
        onSuccess: () => {
          showFloatingMessage(
            <>
              {memberCount} member(s) successfully added to the team{" "}
              <strong>{team.name}</strong>.
            </>,
            {
              noIcon: true,
              state: "success",
            },
          );
        },
        onError: () => {
          showFloatingMessage(
            <>
              Failed to add member(s) to the team <strong>{team.name}</strong>.
              Please try again.
            </>,
            {
              noIcon: true,
              state: "alert",
            },
          );
        },
        onSettled: () => {
          closeModal();
        },
      },
    );
  });

  const getAssignableOptions = () => {
    const teamOptions: AssignableOption[] = [];
    const userOptions: AssignableOption[] = [];

    const sortedAssignables = teamAssignables.data?.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    for (const assignable of sortedAssignables ?? []) {
      const { assignedRoles, availableRoles } = assignable;

      if (!assignedRoles.length && availableRoles.length) {
        const option: AssignableOption = {
          label: assignable.name,
          value: assignable.id,
        };

        if (assignable.type === MemberType.Team) {
          teamOptions.push(option);
        } else {
          userOptions.push(option);
        }
      }
    }

    return [
      { label: "Users", options: userOptions },
      { label: "Teams", options: teamOptions },
    ];
  };

  const assignableOptions = getAssignableOptions();

  const closeModal = () => {
    reset();
    setIsOpen(false);
  };

  /**
   * Close the select menu when clicking outside of the select menu but within the modal
   * Modal's onRequestClose closes the select menu when clicking outside of the modal
   */
  useClickOutside(selectRef, (event) => {
    const modalContentEl = modalContentRef.current;

    if (!modalContentEl || !isMembersMenuOpen) return;

    const clickedWithinModal = modalContentEl.contains(event.target as Node);

    if (clickedWithinModal) {
      toggleMembersMenuIsOpen();
    }
  });

  const toggleMembersMenuIsOpen = () => {
    setIsMembersMenuOpen((value) => !value);

    const selectEl = selectRef.current;
    if (!selectEl) return;

    if (isMembersMenuOpen) {
      selectEl.blur();
    } else {
      selectEl.focus();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      noPadding
      onRequestClose={() => {
        if (isMembersMenuOpen) {
          toggleMembersMenuIsOpen();
        } else {
          closeModal();
        }
      }}
    >
      <div ref={modalContentRef} className={styles.container}>
        <header className="bf-modal-header">Add members to team</header>

        <form onSubmit={(e) => void onSubmit(e)}>
          <Grid className={styles.modal} gap={24}>
            <Input label="Team" disabled placeholder={team.name} />

            <div>
              <Controller
                control={control}
                name="role"
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    label="Role"
                    options={roleOptions}
                    placeholder="Select a role"
                    feedback={fieldState.error?.message ?? ""}
                    state={
                      fieldState.isTouched && fieldState.error
                        ? "alert"
                        : undefined
                    }
                  />
                )}
              />

              <TeamRoleDescriptionTable />
            </div>

            {/* 
              Setting the ref on the <Select> component causes an error when useClickOutside is triggered - "ref.current.contains is not a function"
              Therefore, we wrap the <Select> component in a <div> and set the ref on the <div> instead              
            */}
            <div ref={selectRef}>
              <Controller
                control={control}
                name="members"
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    label="Member(s)"
                    options={assignableOptions}
                    isLoading={teamAssignables.isPending}
                    placeholder="Select one or more"
                    feedback={fieldState.error?.message ?? ""}
                    menuIsOpen={isMembersMenuOpen}
                    onMenuOpen={() => setIsMembersMenuOpen(true)}
                    isMulti
                    state={
                      fieldState.isTouched && fieldState.error
                        ? "alert"
                        : undefined
                    }
                  />
                )}
              />
            </div>

            <Inline>
              <Inline.Separator />

              <Button state="neutral" onClick={() => closeModal()}>
                Cancel
              </Button>

              <Button
                type="submit"
                state={!isValid ? "inactive" : "neutral"}
                variant="filled"
              >
                Add member(s)
              </Button>
            </Inline>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};
