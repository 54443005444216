import styles from "./supportSection.module.css";
import {
  faComment,
  faPhone,
  faTicket,
} from "@fortawesome/pro-regular-svg-icons";
import SupportCard from "./SupportCard";

const SupportSection = () => {
  return (
    <div className={styles.content}>
      <div className={styles.contactOptions}>
        <SupportCard
          icon={faComment}
          title="Chat with us"
          textContent="Start a chat with our support team."
          buttonHref="https://chat.intility.com"
          buttonText="Start chat"
        />

        <SupportCard
          icon={faPhone}
          title="Call us"
          textContent="Do you need help right away? Call us!"
          buttonHref="tel:+4724103300"
          buttonText="+47 24 10 33 00"
        />

        <SupportCard
          icon={faTicket}
          title="Create a ticket"
          textContent="Create a more detailed support description and upload files or images."
          buttonHref="https://my.intility.com/support/new"
          buttonText="Create ticket"
        />
      </div>
    </div>
  );
};

export default SupportSection;
