import { ClusterRole, Role } from "~/constants";

const getRoleFromRoles = (roles: Role[]): Role | null => {
  const isAdmin = roles.includes(Role.Owner);
  const isMember = roles.includes(Role.Member);

  switch (true) {
    case isAdmin:
      return Role.Owner;
    case isMember:
      return Role.Member;
    default:
      return null;
  }
};

const getClusterRoleFromRoles = (roles: ClusterRole[]): ClusterRole | null => {
  const isClusterAdmin = roles.includes(ClusterRole.Admin);
  const isClusterReader = roles.includes(ClusterRole.Reader);

  switch (true) {
    case isClusterAdmin:
      return ClusterRole.Admin;
    case isClusterReader:
      return ClusterRole.Reader;
    default:
      return null;
  }
};

export { getRoleFromRoles, getClusterRoleFromRoles };
