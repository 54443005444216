import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, Role } from "~/constants";

type AddTeamMembersDTO = Array<{
  subject: {
    id: string;
    type: "user" | "group";
  };
  roles: Role[];
}>;

const addTeamMembers = async (teamId: string, dto: AddTeamMembersDTO) => {
  return api
    .post(`api/v1/teams/${teamId}/members`, {
      prefixUrl: API.IDP.url,
      json: dto,
    })
    .json();
};

export const useAddTeamMembers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, dto }: { teamId: string; dto: AddTeamMembersDTO }) =>
      addTeamMembers(teamId, dto),
    onSuccess: (data, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.TeamMembers, variables.teamId],
      });

      for (const member of variables.dto) {
        if (member.subject.type === "user") {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryClient.invalidateQueries({
            queryKey: [QueryKey.UserTeams, member.subject.id],
          });
        }
      }
    },
  });
};
