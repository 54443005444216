import { faKey, faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Box, Button, Grid, Icon } from "@intility/bifrost-react";
import { useContext } from "react";
import { ClusterRole, ClusterStatus, ClusterStatusIcon } from "~/constants";
import { ModalContext } from "~/context/ModalContext";
import type { Cluster } from "~/types";
import { cn } from "~/utils/clsx";
import { getClusterStatus } from "~/utils/clusterStatus";
import { DeleteModal } from "../clusterList/DeleteModal";
import styles from "./ClusterInfoCard.module.css";
import { Link } from "react-router";
import { ClusterSpecificationCards } from "./ClusterSpecificationCards";
import { useRole } from "~/hooks/useRole";

interface ClusterInfoCardProps {
  cluster: Cluster;
}

export const ClusterInfoCard = ({ cluster }: ClusterInfoCardProps) => {
  const clusterName = cluster.name;
  const clusterStatus = getClusterStatus(cluster);

  const isClusterAdmin = cluster.roles.includes(ClusterRole.Admin);
  const isOrgAdmin = useRole().isAdmin;
  const showAdminActions = isClusterAdmin || isOrgAdmin;

  return (
    <Box
      padding
      shadow
      radius
      background="base-3"
      className={styles.cardContainer}
    >
      <div className={styles.infoHeader}>
        <div>
          <div>
            <Icon
              icon={ClusterStatusIcon[clusterStatus].icon}
              color={ClusterStatusIcon[clusterStatus].color}
              spin={clusterStatus === ClusterStatus.deploying}
              marginRight
            />

            <span>
              {clusterStatus === ClusterStatus.deploying && "In deployment"}
              {clusterStatus === ClusterStatus.ready && "Ready"}
              {clusterStatus === ClusterStatus.notReady && "Not ready"}
            </span>
          </div>

          <h3 className={cn("bf-h3", styles.clusterName)}>{clusterName}</h3>
        </div>

        <div>
          <div
            className={cn("from-medium", styles.clusterActionsButtonContainer)}
          >
            <ClusterAccessLink />
            {showAdminActions && <ClusterAdminButtons cluster={cluster} />}
          </div>
        </div>
      </div>

      <Grid medium={2} large={3} className={styles.specificationsContainer}>
        <ClusterSpecificationCards cluster={cluster} />
      </Grid>

      <div className={cn("to-medium", styles.clusterActionsButtonContainer)}>
        <ClusterAccessLink />
        {showAdminActions && <ClusterAdminButtons cluster={cluster} />}
      </div>
    </Box>
  );
};

const ClusterAdminButtons = ({ cluster }: { cluster: Cluster }) => {
  return (
    <>
      <hr className={cn("from-small", styles.divider)} />
      <EditClusterSpecLink />
      <hr className={cn("from-small", styles.divider)} />
      <DeleteClusterButton cluster={cluster} />
    </>
  );
};

const ClusterAccessLink = () => {
  return (
    <Link to="access">
      <Button state="neutral" variant="flat" className={styles.adminButton}>
        <Icon icon={faKey} marginRight />
        Cluster access
      </Button>
    </Link>
  );
};

const EditClusterSpecLink = () => {
  return (
    <Link to="edit">
      <Button state="neutral" variant="flat" className={styles.adminButton}>
        <Icon icon={faPencil} marginRight />
        Edit cluster spec
      </Button>
    </Link>
  );
};

const DeleteClusterButton = ({ cluster }: { cluster: Cluster }) => {
  const { handleModal } = useContext(ModalContext);

  return (
    <Button
      state="alert"
      variant="flat"
      onClick={() =>
        handleModal({
          header: <>Delete cluster</>,
          content: <DeleteModal cluster={cluster} />,
        })
      }
    >
      <Icon icon={faTrash} marginRight />
      Delete cluster
    </Button>
  );
};
