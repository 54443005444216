import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, Role } from "~/constants";

export type UserDTO = {
  id: string;
  name: string;
  upn: string;
  roles: Role[];
};

const getUsers = () => {
  return api.get("api/v1/users", { prefixUrl: API.IDP.url }).json<UserDTO[]>();
};

export const usersQuery = () => ({
  queryKey: [QueryKey.Users],
  queryFn: getUsers,
});

export const useUsers = () => {
  return useQuery(usersQuery());
};
