import { Box, Breadcrumbs } from "@intility/bifrost-react";
import { Link, useLocation } from "react-router";
import type { Cluster } from "~/types";
import styles from "./ClusterBreadcrumbs.module.css";

interface ClusterBreadcrumbsProps {
  cluster: Cluster | undefined;
}

export const ClusterBreadcrumbs = ({ cluster }: ClusterBreadcrumbsProps) => {
  const { pathname } = useLocation();

  const clusterName = cluster?.name;
  const clusterId = cluster?.id;

  const breadcrumbs = [
    { route: "/clusters", label: "Clusters" },
    { route: `/clusters/${clusterId}`, label: clusterName },
    { route: `/clusters/${clusterId}/access`, label: "Access" },
    { route: `/clusters/${clusterId}/edit`, label: "Edit cluster spec" },
  ].filter((breadcrumb) => pathname.includes(breadcrumb.route));

  const lastIndex = breadcrumbs.length - 1;

  return (
    <Box shadow radius background="base-3" className={styles.box}>
      <Breadcrumbs>
        {breadcrumbs.map(({ route, label }, index) => (
          <Breadcrumbs.Item key={route}>
            {index === lastIndex ? (
              <span>{label}</span>
            ) : (
              <Link to={route}>{label}</Link>
            )}
          </Breadcrumbs.Item>
        ))}
      </Breadcrumbs>
    </Box>
  );
};
