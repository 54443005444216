import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router";
import type { UserDTO } from "../../api/getUsers";
import { UserTypeBadge } from "../UserTypeBadge";
import styles from "./UsersTable.module.css";
import { getRoleFromRoles } from "~/utils/getRoleFromRoles";
import { sortByRole } from "~/utils/sortByRole";
import { Icon, Tooltip } from "@intility/bifrost-react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

const columnHelper = createColumnHelper<UserDTO>();

export const userColumns = [
  columnHelper.accessor("name", {
    header: "Name",
    id: "name",
    meta: {
      cellClassName: styles.userNameCell,
    },
    cell: function Cell(cell) {
      const user = cell.row.original;

      return (
        <Link to={`/users/${user.id}`} className="bf-title-link">
          {user.name}
        </Link>
      );
    },
  }),
  columnHelper.accessor("upn", {
    header: "UPN",
    meta: {
      fromSize: "small",
    },
  }),
  columnHelper.accessor((user) => getRoleFromRoles(user.roles), {
    id: "role",
    header: () => <RoleHeader />,
    sortingFn: (rowA, rowB) => {
      const roleA = getRoleFromRoles(rowA.original.roles);
      const roleB = getRoleFromRoles(rowB.original.roles);

      return sortByRole(roleA, roleB);
    },
    cell: function Cell(cell) {
      const user = cell.row.original;

      return <UserTypeBadge roles={user.roles} />;
    },
  }),
];

const RoleHeader = () => {
  return (
    <>
      <Tooltip content="Role within organization">
        <Icon icon={faInfoCircle} marginRight />
      </Tooltip>
      Role
    </>
  );
};
