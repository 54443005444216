import { useBreakpoint } from "@intility/bifrost-react";
import type { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { DataTable } from "~/components/table/DataTable";
import { useSearch } from "~/hooks/useSearch";
import { type UserDTO, useUsers } from "../../api/getUsers";
import styles from "./UsersTable.module.css";
import { userColumns } from "./UsersTableColumns";

export const UsersTable = () => {
  const users = useUsers();
  const toSmall = useBreakpoint(null, "small");

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 8,
  });

  const [search] = useSearch();

  return (
    <DataTable
      className={styles.table}
      data={users.data}
      isLoading={users.isPending}
      columns={userColumns}
      noBorder
      ExpandedRowComponent={toSmall ? ExpandedUserRow : undefined}
      onPaginationChange={setPagination}
      state={{ globalFilter: search, pagination }}
      initialState={{
        sorting: [
          { id: "role", desc: true },
          { id: "name", desc: false },
        ],
      }}
    />
  );
};

const ExpandedUserRow = ({ rowData }: { rowData: UserDTO }) => {
  return <div>{rowData.upn}</div>;
};
