import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, Role } from "~/constants";

export type TeamDTO = {
  description: string;
  name: string;
  roles: Role[];
  id: string;
};

const getTeam = (teamId: string) => {
  return api
    .get(`api/v1/teams/${teamId}`, { prefixUrl: API.IDP.url })
    .json<TeamDTO>();
};

export const teamQuery = (teamId: string) => ({
  queryKey: [QueryKey.Team, teamId],
  queryFn: () => getTeam(teamId),
  enabled: !!teamId,
});

export const useTeam = (teamId: string) => {
  return useQuery(teamQuery(teamId));
};
