import type { TeamDTO } from "../../api/getTeam";
import styles from "./TeamProfile.module.css";
import { Button, Icon } from "@intility/bifrost-react";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import { EditTeamModal } from "./EditTeamModal";
import { TeamAvatar } from "../Avatar";
import { useRole } from "~/hooks/useRole";
import { Role } from "~/constants";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";

interface TeamProfileProps {
  team: TeamDTO;
}

export const TeamProfile = ({ team }: TeamProfileProps) => {
  const { handleModal } = useContext(ModalContext);
  const role = useRole();

  const isTeamOwner = team.roles.includes(Role.Owner);
  const canEditTeam = role.isAdmin || isTeamOwner;

  return (
    <div className={styles.container}>
      <TeamAvatar size={80} className="bf-h1 from-small" />

      <div className={styles.teamInfoContainer}>
        <h3 className="bf-h3">{team.name}</h3>
        <div className={styles.teamDescriptionContainer}>
          {team.description}

          {canEditTeam && (
            <Button
              variant="flat"
              state="neutral"
              noPadding
              small
              className={styles.editDescriptionButton}
              onClick={() =>
                handleModal({
                  content: <EditTeamModal team={team} />,
                  header: <>Edit team</>,
                })
              }
            >
              <Icon icon={faPenToSquare} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
