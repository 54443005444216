import { EventType, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { type PropsWithChildren, useEffect, useState } from "react";
import { instance, loginHint } from "./instance";
import { useLocation, useNavigate } from "react-router";

export const loginRequest = {
  scopes: [
    "User.Read",
    "api://cd5aff56-575c-4e7e-b68e-3f67fa42eb31/user_impersonation",
  ],
  loginHint: loginHint,
};

/** Attempts a silent login and redirects to /login if unsuccessful */
export const ProtectedAppAuthentication = ({ children }: PropsWithChildren) => {
  const { error } = useMsalAuthentication(InteractionType.Silent, {
    ...loginRequest,
    redirectUri: `${window.location.origin}/blank.html`,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate("/login");
    }
  }, [error, navigate]);

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

/** Attempts a silent login. Redirect to /home if successful, otherwise render the children. */
export const PublicAppAuthentication = ({ children }: PropsWithChildren) => {
  const [authStatus, setAuthStatus] = useState<
    "NOT_STARTED" | "IN_PROGRESS" | "FAILED"
  >("NOT_STARTED");

  const { result } = useMsalAuthentication(InteractionType.Silent, {
    ...loginRequest,
    redirectUri: `${window.location.origin}/blank.html`,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      const { eventType } = message;

      /**
       * useMsalAuthentication initiates a login if a user is not already signed in, otherwise it attempts to acquire a token.
       * The initial authStatus is NOT_STARTED to prevent a flash of content before we know what MSAL wants to do.
       * Once we get an SSO_SILENT_START or ACQUIRE_TOKEN_START event, we set authStatus to IN_PROGRESS. While authStatus is IN_PROGRESS, we don't render anything.
       * If we get a SSO_SILENT_FAILURE or ACQUIRE_TOKEN_FAILURE event, we set authStatus to FAILED. At this point the user will see {children} (landing page / login screen)
       * If we get an SSO_SILENT_SUCCESS or ACQUIRE_TOKEN_SUCCESS event, we redirect to /home. We continue to render nothing as the user should be redirected.
       */
      if (
        eventType === EventType.SSO_SILENT_START ||
        eventType === EventType.ACQUIRE_TOKEN_START
      ) {
        setAuthStatus("IN_PROGRESS");
      } else if (
        eventType === EventType.SSO_SILENT_FAILURE ||
        eventType === EventType.ACQUIRE_TOKEN_FAILURE
      ) {
        setAuthStatus("FAILED");
      } else if (
        eventType === EventType.SSO_SILENT_SUCCESS ||
        eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        void navigate("/home");
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [navigate]);

  const pathname = location.pathname;

  // We want the login screen to remain visible while the login is in progress
  if (pathname === "/login" && authStatus === "IN_PROGRESS") {
    return <>{children}</>;
  }

  // If login/token aquiring has not started yet, return null
  if (authStatus === "NOT_STARTED") {
    return null;
  }

  // If login/token aquiring is in progress, return null
  if (authStatus === "IN_PROGRESS") {
    return null;
  }

  // If the silent login was successful, return null and wait for the redirect to /home
  if (result) {
    return null;
  }

  return <>{children}</>;
};
