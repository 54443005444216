import { ClusterRole, Role } from "~/constants";

const sortByRole = (a: Role | null, b: Role | null) => {
  const roleOrder = [Role.Owner, Role.Member, null];

  return roleOrder.indexOf(b) - roleOrder.indexOf(a);
};

const sortByClusterRole = (a: ClusterRole | null, b: ClusterRole | null) => {
  const roleOrder = [ClusterRole.Admin, ClusterRole.Reader, null];

  return roleOrder.indexOf(b) - roleOrder.indexOf(a);
};

export { sortByRole, sortByClusterRole };
