import type { NewClusterForm, NewClusterStep } from "~/types";
import { Icon, Menu } from "@intility/bifrost-react";
import type { StepProps } from "@intility/bifrost-react/Icon";
import { cn } from "~/utils/clsx";
import type { NewClusterStepName } from "../../routes/NewCluster";

type StepVariant = StepProps["variant"];

const getIconVariant = (
  activeStep: NewClusterStep,
  step: NewClusterStep,
  formData: NewClusterForm,
  visitedSteps: NewClusterStepName[],
): StepVariant => {
  switch (true) {
    case activeStep.id === step.id:
      return "active";
    case step.isComplete(formData) && visitedSteps.includes(step.name):
      return "completed";
    case step.id > activeStep.id:
      return "default";
    default:
      return "incomplete";
  }
};

export const CreateClusterMenuSteps = ({
  steps,
  activeStep,
  handleSetActiveStep,
  formData,
  visitedSteps,
}: {
  steps: NewClusterStep[];
  activeStep: NewClusterStep;
  handleSetActiveStep: (activeStep: NewClusterStep) => void;
  formData: NewClusterForm;
  visitedSteps: NewClusterStepName[];
}) => {
  return (
    <>
      {steps.map((step) => {
        const iconVariant = getIconVariant(
          activeStep,
          step,
          formData,
          visitedSteps,
        );

        return (
          <button
            key={step.name}
            className={cn({
              active: activeStep.name === step.name,
            })}
          >
            <Menu.Item
              key={step.name}
              onClick={() => handleSetActiveStep(step)}
            >
              <Icon.Step marginRight variant={iconVariant} />
              {step.title}
            </Menu.Item>
          </button>
        );
      })}
    </>
  );
};
