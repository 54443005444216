import styles from "./UserProfile.module.css";
import type { UserDTO } from "../../api/getUsers";
import { UserTypeBadge } from "../UserTypeBadge";
import { UserAvatar } from "../Avatar";
import { Button, Icon } from "@intility/bifrost-react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { useRole } from "~/hooks/useRole";
import { ModalContext } from "~/context/ModalContext";
import { useContext } from "react";
import { EditOrgRoleModal } from "./EditOrgRoleModal";

interface UserProfileProps {
  user: UserDTO;
}

export const UserProfile = ({ user }: UserProfileProps) => {
  const { handleModal } = useContext(ModalContext);

  const isOrgAdmin = useRole().isAdmin;

  return (
    <div className={styles.container}>
      <UserAvatar size={80} className="bf-h1 from-small" />

      <div className={styles.userInfoContainer}>
        <div>
          <h3 className="bf-h3">{user.name}</h3>
          <span className={styles.userUpn}>{user.upn}</span>
        </div>

        <div className={styles.roleContainer}>
          <UserTypeBadge roles={user.roles} />

          {isOrgAdmin && (
            <>
              <hr className={styles.divider} />

              <Button
                state="neutral"
                variant="flat"
                small
                onClick={() =>
                  handleModal({
                    header: <>Edit organization role</>,
                    content: <EditOrgRoleModal user={user} />,
                  })
                }
              >
                <Icon icon={faPencil} marginRight />
                Edit organization role
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
