import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { MemberType, QueryKey, Role } from "~/constants";

export type TeamMemberDTO = {
  roles: Role[];
  subject: {
    type: MemberType;
    name: string;
    details: string;
    id: string;
  };
};

const getTeamMembers = async (teamId: string) => {
  return api
    .get(`api/v1/teams/${teamId}/members`, { prefixUrl: API.IDP.url })
    .json<TeamMemberDTO[]>();
};

export const useTeamMembers = (teamId: string) => {
  return useQuery({
    queryKey: [QueryKey.TeamMembers, teamId],
    queryFn: () => getTeamMembers(teamId),
  });
};
