import { Button } from "@intility/bifrost-react";
import intilityShorthandLogoDark from "~/assets/intility-shorthand-logo-dark.svg";
import intilityShorthandLogoLight from "~/assets/intility-shorthand-logo-light.svg";
import { instance } from "~/auth";
import styles from "./SignInButton.module.css";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "~/auth/Authentication";
import { useNavigate } from "react-router";
import { captureException } from "@sentry/react";
import { useMsal } from "@azure/msal-react";

export const SignInButton = () => {
  const navigate = useNavigate();
  const { inProgress } = useMsal();

  const isLoggingIn = inProgress !== InteractionStatus.None;

  const handleSignIn = async () => {
    if (isLoggingIn) {
      return;
    }

    await instance
      .ssoSilent({
        ...loginRequest,
        redirectUri: `${window.location.origin}/blank.html`,
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate("/home");
      })
      .catch(async (error) => {
        if (!(error instanceof InteractionRequiredAuthError)) {
          captureException(error);
        }

        // localhost needs its own redirectUri to not clash with indev's redirectUri
        const redirectUri = import.meta.env.DEV
          ? `${window.location.origin}/login`
          : undefined;

        await instance.loginRedirect({
          ...loginRequest,
          redirectStartPage: `${window.location.origin}/home`,
          redirectUri,
        });
      });
  };

  return (
    <Button
      variant="filled"
      state={isLoggingIn ? "inactive" : "neutral"}
      pill
      className={styles.signInButton}
      onClick={() => void handleSignIn()}
    >
      <picture>
        <source
          srcSet={intilityShorthandLogoLight}
          media="(prefers-color-scheme: light)"
        />
        <source
          srcSet={intilityShorthandLogoDark}
          media="(prefers-color-scheme: dark)"
        />
        <img src={intilityShorthandLogoDark} alt="" />
      </picture>
      Sign in with Intility (SSO)
      {isLoggingIn && "…"}
    </Button>
  );
};
