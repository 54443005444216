import { Button, Icon, Section } from "@intility/bifrost-react";
import styles from "./roleSection.module.css";
import womanPeace from "~/assets/woman-peace.svg";
import manGreeting from "~/assets/man-greeting.svg";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router";
import { useRole } from "~/hooks/useRole";

interface RoleSectionProps {
  className?: string;
}

const RoleSection = ({ className }: RoleSectionProps) => {
  const role = useRole();

  return (
    <Section className={className}>
      <div className={styles.roleCard}>
        {role.isAdmin ? (
          <>
            <div className={styles.roleIllustration}>
              <img src={womanPeace} alt="woman making peace symbol" />
            </div>
            <div className={styles.roleContent}>
              <span className={styles.roleLabel}>Role within organization</span>
              <h5 className="bf-h5">Admin</h5>
              <span>
                You can manage all teams and access articles. Additionally, you
                can create and access all clusters.
              </span>
              <ViewClustersButton />
            </div>
          </>
        ) : role.isMember ? (
          <>
            <div className={styles.roleIllustration}>
              <img src={manGreeting} alt="man greeting" />
            </div>
            <div className={styles.roleContent}>
              <span className={styles.roleLabel}>Role within organization</span>
              <h5 className="bf-h5">Member</h5>
              <span>
                You can manage the teams you own and access articles.
                Additionally, you can create and access assigned clusters.
              </span>
              <ViewClustersButton />
            </div>
          </>
        ) : null}
      </div>
    </Section>
  );
};

const ViewClustersButton = () => {
  return (
    <Link className={styles.roleButtonAnchor} to="/clusters">
      <Button
        className={styles.roleButton}
        state="neutral"
        pill
        variant="filled"
      >
        View clusters
        <Icon icon={faArrowRight} marginLeft />
      </Button>
    </Link>
  );
};

export default RoleSection;
