import {
  Button,
  Input,
  TextArea,
  useFloatingMessage,
} from "@intility/bifrost-react";
import styles from "./CreateTeamModal.module.css";
import { useCreateTeam } from "../../api/createTeam";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router";

export const createTeamSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Must be at least 3 characters" })
    .max(30, { message: "Cannot be longer than 30 characters" })
    .regex(/^[a-zA-Z0-9]+([-_ ]{0,1}[a-zA-Z0-9])+$/, {
      message: "Invalid name",
    }),
  description: z
    .string()
    .min(1, { message: "Cannot be empty" })
    .max(70, { message: "Cannot be longer than 70 characters" }),
});

export const CreateTeamModal = () => {
  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const navigate = useNavigate();
  const createTeam = useCreateTeam();

  const { control, handleSubmit, formState } = useForm({
    resolver: zodResolver(createTeamSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    createTeam.mutate(data, {
      onSuccess: (response) => {
        showFloatingMessage(
          <>
            The team <strong>{data.name}</strong> has been successfully created.
          </>,
          {
            state: "success",
            noIcon: true,
          },
        );

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(`/teams/${response.id}`);
      },
      onError: () => {
        showFloatingMessage(
          <>
            An error occurred while attempting to create the team{" "}
            <strong>{data.name}</strong>. Please try again later.
          </>,
          {
            state: "alert",
            noIcon: true,
          },
        );
      },
      onSettled: () => {
        handleModal({});
      },
    });
  });

  return (
    <form className={styles.container} onSubmit={(e) => void onSubmit(e)}>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            label="Team name"
            placeholder="Create a name"
            feedback={fieldState.error?.message ?? ""}
            required
            state={
              fieldState.isTouched && fieldState.error ? "alert" : undefined
            }
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            label="Description"
            placeholder="Describe the team"
            className={styles.descriptionInput}
            feedback={fieldState.error?.message ?? ""}
            required
            state={
              fieldState.isTouched && fieldState.error ? "alert" : undefined
            }
          />
        )}
      />

      <div className={styles.buttonContainer}>
        <Button variant="flat" state="neutral" onClick={() => handleModal({})}>
          Cancel
        </Button>

        <Button
          type="submit"
          variant="filled"
          state={!formState.isValid ? "inactive" : "neutral"}
        >
          Create
        </Button>
      </div>
    </form>
  );
};
