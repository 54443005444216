import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Table, Icon } from "@intility/bifrost-react";
import styles from "./TeamRoleDescriptionTable.module.css";
import { faX } from "@fortawesome/pro-regular-svg-icons";

export const TeamRoleDescriptionTable = () => {
  return (
    <Table vertical noBorder className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Member</Table.HeaderCell>
          <Table.HeaderCell>Owner</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.HeaderCell>Inherit team permissions</Table.HeaderCell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Edit team memberships</Table.HeaderCell>
          <Table.Cell>
            <CrossIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Edit team details</Table.HeaderCell>
          <Table.Cell>
            <CrossIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const CheckIcon = () => (
  <Icon icon={faCheck} color="var(--bfc-base-c-success)" />
);

const CrossIcon = () => <Icon icon={faX} color="var(--bfc-base-c-alert)" />;
