import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey, type ClusterRole, type MemberType } from "~/constants";

type ClusterAssignableDTO = {
  name: string;
  details: string;
  type: MemberType;
  availableRoles: ClusterRole[];
  assignedRoles: ClusterRole[];
  id: string;
};

const getClusterAssignables = (clusterId: string) => {
  return api
    .get(`api/v1/clusters/${clusterId}/assignables`, { prefixUrl: API.IDP.url })
    .json<ClusterAssignableDTO[]>();
};

export const useClusterAssignables = (clusterId: string) => {
  return useQuery({
    queryKey: [QueryKey.ClusterAssignables, clusterId],
    queryFn: () => getClusterAssignables(clusterId),
  });
};
