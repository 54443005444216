import { Badge } from "@intility/bifrost-react";
import { Role } from "~/constants";

export const RoleBadge = ({ roles }: { roles: Role[] }) => {
  const isAdmin = roles.includes(Role.Owner);
  const isMember = roles.includes(Role.Member);

  switch (true) {
    case isAdmin:
      return (
        <Badge state="neutral" inverted>
          Owner
        </Badge>
      );

    case isMember:
      return <Badge state="neutral">Member</Badge>;

    default:
      return null;
  }
};
