import { Badge } from "@intility/bifrost-react";
import { Role } from "~/constants";

export const UserTypeBadge = ({ roles }: { roles: Role[] }) => {
  const isAdmin = roles.includes(Role.Owner);
  const isMember = roles.includes(Role.Member);

  switch (true) {
    case isAdmin:
      return (
        <Badge pill state="attn" inverted>
          Admin
        </Badge>
      );

    case isMember:
      return (
        <Badge pill state="attn">
          Member
        </Badge>
      );

    default:
      return null;
  }
};
