import { wrapCreateBrowserRouterV7 } from "@sentry/react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router";
import { ErrorPage } from "./pages/error/ErrorPage";
import HelpCenterPage from "./features/help-center/routes/HelpCenter";
import Home from "./pages/home/Home";
import { Landing } from "./features/landing/routes/Landing";
import { SignInPage } from "./features/sign-in/SignInPage";
import { AppProvider, ProtectedApp, PublicApp } from "./providers/app";
import { Clusters, clustersLoader } from "./features/clusters/routes/Clusters";
import { NewCluster } from "./features/clusters/routes/NewCluster";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Cluster, clusterLoader } from "./features/clusters/routes/Cluster";
import { ClusterAccess } from "./features/clusters/routes/ClusterAccess";
import {
  teamsLoader,
  TeamsTab,
} from "./features/access/components/access/TeamsTab";
import {
  usersLoader,
  UsersTab,
} from "./features/access/components/access/UsersTab";
import { Access } from "./features/access/routes/Access";
import { TeamDetails, teamLoader } from "./features/access/routes/TeamDetails";
import { UserDetails, userLoader } from "./features/access/routes/UserDetails";
import { EditCluster } from "./features/clusters/routes/EditCluster";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
  },
});

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter =
  wrapCreateBrowserRouterV7(createBrowserRouter);

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <Outlet />
          </AppProvider>
        </QueryClientProvider>
      }
    >
      <Route path="*" element={<ErrorPage code={404} />} />

      <Route element={<PublicApp />}>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<SignInPage />} />
      </Route>

      <Route element={<ProtectedApp />}>
        <Route path="/home" element={<Home />} />

        <Route path="/clusters">
          <Route
            index
            element={<Clusters />}
            loader={clustersLoader(queryClient)}
          />

          <Route path="new" element={<NewCluster />} />

          <Route path=":clusterId">
            <Route
              index
              element={<Cluster />}
              loader={({ params }) =>
                clusterLoader(params.clusterId ?? "", queryClient)
              }
            />
            <Route
              path="access"
              element={<ClusterAccess />}
              loader={({ params }) =>
                clusterLoader(params.clusterId ?? "", queryClient)
              }
            />
            <Route
              path="edit"
              element={<EditCluster />}
              loader={({ params }) =>
                clusterLoader(params.clusterId ?? "", queryClient)
              }
            />
          </Route>
        </Route>

        <Route element={<Access />}>
          <Route
            path="/teams"
            element={<TeamsTab />}
            loader={teamsLoader(queryClient)}
          />
          <Route
            path="/users"
            element={<UsersTab />}
            loader={usersLoader(queryClient)}
          />
        </Route>

        <Route
          path="/users/:userId"
          element={<UserDetails />}
          loader={({ params }) => userLoader(params.userId ?? "", queryClient)}
        />
        <Route
          path="/teams/:teamId"
          element={<TeamDetails />}
          loader={({ params }) => teamLoader(params.teamId ?? "", queryClient)}
        />

        <Route path="/helpcenter" element={<HelpCenterPage />} />
      </Route>
    </Route>,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export { router };
