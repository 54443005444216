import { z } from "zod";

const envSchema = z.object({
  idpApiUrl: z.string(),
  otelServiceName: z.string().optional(),
  otelCollectorUrl: z.string().optional(),
  sentryDsn: z.string().optional(),
  sentryRelease: z.string().optional(),
  sentryEnv: z.enum(["production", "staging"]).optional(),
  appVersion: z.string().optional(),
});

export const env = envSchema.parse({
  idpApiUrl: import.meta.env.VITE_BACKEND_BASE as unknown,
  otelServiceName: import.meta.env.VITE_OTEL_SERVICE_NAME as unknown,
  otelCollectorUrl: import.meta.env.VITE_OTEL_COLLECTOR_URL as unknown,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN as unknown,
  sentryRelease: import.meta.env.VITE_SENTRY_RELEASE as unknown,
  sentryEnv: import.meta.env.VITE_SENTRY_ENVIRONMENT as unknown,
  appVersion: import.meta.env.VITE_APP_VERSION as unknown,
});
