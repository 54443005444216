import { MsalProvider } from "@azure/msal-react";
import { FloatingMessage } from "@intility/bifrost-react";
import type { ReactNode } from "react";
import { Outlet, ScrollRestoration } from "react-router";
import { instance } from "~/auth";
import {
  ProtectedAppAuthentication,
  PublicAppAuthentication,
} from "~/auth/Authentication";
import Navigation from "~/components/Navigation";
import { ModalProvider } from "~/context/ModalContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "~/context/AuthContext";
import { NuqsAdapter } from "nuqs/adapters/react-router/v7";

type ProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: ProviderProps) => {
  return (
    <>
      <NuqsAdapter>
        <MsalProvider instance={instance}>{children}</MsalProvider>
      </NuqsAdapter>
      <ScrollRestoration />
    </>
  );
};

export const ProtectedApp = () => {
  return (
    <ProtectedAppAuthentication>
      <AuthProvider>
        <FloatingMessage timeout={5000}>
          <ModalProvider>
            <Navigation>
              <Outlet />
            </Navigation>
          </ModalProvider>
        </FloatingMessage>

        {import.meta.env.DEV && <ReactQueryDevtools />}
      </AuthProvider>
    </ProtectedAppAuthentication>
  );
};

export const PublicApp = () => {
  return <PublicAppAuthentication>{<Outlet />}</PublicAppAuthentication>;
};
